import React, { useContext, useEffect, useState } from "react";
import { BusyIndicator } from "../../core/components";
import { ServiceContext } from "@msx/platform-services";


interface V2_StartUpProps {
  setIsUserLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const V2_StartUp: React.FC<V2_StartUpProps> = ({ setIsUserLoggedIn }) => {
  const [loading, setLoading] = useState(true);
  const { authClient } = useContext(ServiceContext);
  useEffect(() => {
    const handleAuthFlow = async () => {
      try {
        if (!authClient) {
          throw new Error("AuthClient is not initialized");
        }

        const isLoggedIn = await authClient.isLoggedIn();
        if (!isLoggedIn) {
          // Trigger login flow
          await authClient.login();
        }

        setIsUserLoggedIn(true); // User is now logged in
        setLoading(false);
      } catch (error) {
        console.error("Error during authentication:", error);
        setLoading(false); // To avoid indefinite spinner
      }
    };

    handleAuthFlow();
  }, [setIsUserLoggedIn]);

  if (loading) {
    return <BusyIndicator message="Logging In ..." />;
  }

  return null; // No additional UI for V2_StartUp after login
};

export default V2_StartUp;
