import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  SETChileComponentName,
  SETHasServiceRequestTypeLogData,
  SETIsCustomeLog,
  SETLogEventData,
  SETServiceRequestTypeLogData,
} from "../../../../../core/store";

const DynamicDropdownGeneric = ({
  numberOfDropdown = 0,
  firstDropDownOptions = [],
  dialogTitle = "",
  dialogSubText = "",
  isVisible = false,
  modalValue = "",
  openModal = (a, b) => {},
  openWebForm = (e) => {},
  onHide = () => {},
  onCancel = () => {},
  secondDropDownOptions = [],
  passSegmentDataToIris = (segment,subSegment)=>{},
  isDependent = true,
  getAllSubSegmentFromSegment = (a)=>{},

}) => {
  const [firstSelectedOption, setFirstSelectedOption] = useState(null);
  const [secondSelectedOption, setSecondSelectedOption] = useState("");
  const [firstSelectedText, setFirstSelectedText] = useState(null);
  const [secondSelectedText, setSecondSelectedText] = useState("");
  const [value, setValue] = useState(modalValue);
  const [openInNewTab, setOpenInNewTab] = useState(false);
  const reduxDispatch = useDispatch();

  const handleChangeFirstDropdown = (event, option) => {
    if(numberOfDropdown === 1){
    setFirstSelectedOption(option);
        setValue(option.value);
      setOpenInNewTab(option.openinBrowserTab);
    isVisible = false;
}
    else{
        setFirstSelectedOption(option.key)
        setFirstSelectedText(option.text)
        getAllSubSegmentFromSegment(option.key)
    }
  };


  const handleSecondDropdownChange = (event,option)=>{
    setSecondSelectedOption(option.key);
    setSecondSelectedText(option.text)
  }

  const onGetStarted = async () => {
    // Parse the URL
    const urlParams = new URLSearchParams(value);

    // Check if the URL contains the requestType parameter
    if (urlParams.has("requestType")) {
      // Get the value of the requestType parameter
      const requestType = urlParams.get("requestType");

      const formattedRequestType = requestType.replace(/\s/g, "").toLowerCase();
      const formattedSelectedOption = firstSelectedOption.text
        .replace(/\s/g, "")
        .toLowerCase();
      console.log(requestType);
      // Check if the requestType meets your criteria
      if (
        firstSelectedOption &&
        formattedRequestType === formattedSelectedOption
      ) {
        // Open the webform or perform any action
        setFirstSelectedOption(null);
        openWebForm(formattedRequestType);
        onCancel();
      }
    } else {
      setFirstSelectedOption(null);
      openModal(value, openInNewTab);
      onCancel();
    }
    // Start Event Logging //
    reduxDispatch(SETChileComponentName("Dialog " + dialogTitle));
    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(
      SETLogEventData({
        elementName: "Request Type dropdown Start",
        elementId: "",
        action: "click",
        message: `User click on Request Type dropdown Start`,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(
      SETServiceRequestTypeLogData({
        ServiceName: dialogTitle,
        RequestType: firstSelectedOption.text || "",
        TileName: "",
        IsNonIrisService: "true",
        IrisUtterance: "",
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
  };

  const handleSave = () => {
    if (numberOfDropdown === 1) {
      onGetStarted();
    } else if (numberOfDropdown === 2) {
      // Start Event Logging //
      reduxDispatch(SETChileComponentName("v2SegSubSegDropdown" ));
      reduxDispatch(SETHasServiceRequestTypeLogData(true));
      reduxDispatch(
        SETLogEventData({
          elementName: "Save Button Click v2SegSubSegDropdown Seg",
          elementId: "",
          action: "click",
          message: `Save Button Click v2SegSubSegDropdown`,
          UserSettingsSegment:firstSelectedText,
          UserSettingsSubSegment:secondSelectedText,
          profileSetting: `v2`,
        })
      );
      reduxDispatch(
        SETServiceRequestTypeLogData({
          ServiceName: dialogTitle,
          TileName: "",
          IsNonIrisService: "",
          IrisUtterance: "",
          UserSettingsSegment:firstSelectedText,
          UserSettingsSubSegment:secondSelectedText,
          profileSetting: `v2`,
        })
      );
      reduxDispatch(SETIsCustomeLog(true));
      // End Event Logging //
      passSegmentDataToIris(firstSelectedText,secondSelectedText);
      onCancel();
    }
  };

  const handleDropdowns = () => {
    switch (numberOfDropdown) {
      case 1: {
        return (
          <>
          
            <Dropdown
              placeholder="Select an option"
              options={firstDropDownOptions}
              selectedKey={
                firstSelectedOption ? firstSelectedOption.key : undefined
              }
              onChange={handleChangeFirstDropdown}
              className="customDropdown"
              aria-label={dialogTitle + " " + dialogSubText}
              styles={{
                root: {
                  width: 300,
                  marginTop: -20,
                  marginBottom: 10,
                },
              }}
            />
          </>
        );
      }
      case 2: {
        return <>
        <Dropdown
           label="Segment"
                  selectedKey={firstSelectedOption}
                  onChange={handleChangeFirstDropdown}
                  options={firstDropDownOptions}
                  required
                  placeholder="Please select a Segment"
                  styles={{
                    root: {
                      width: 300,
                      marginTop: -20,
                      marginBottom: 10,
                    },
                  }}
                />


{(!isDependent || (isDependent && firstSelectedOption)) && (
            <Dropdown
              label="Subsegment"
              selectedKey={secondSelectedOption}
              onChange={handleSecondDropdownChange}
              options={secondDropDownOptions}
              required
              placeholder="Please select a Subsegment"
              styles={{
                root: {
                  width: 300,
                  marginBottom: 10,
                },
              }}
            />
          )}


        </>;
      }
      default: {
        return <></>;
      }
    }
  };


  const checkIsFieldsrequired = () => {
    return (
      (numberOfDropdown === 1 && !firstSelectedOption) ||
      (numberOfDropdown === 2 && (!firstSelectedOption || !secondSelectedOption))
    );
  };

  return (
    <>
      <Dialog
        hidden={!isVisible}
        onDismiss={() => {
          onHide();
          setFirstSelectedOption(null);
          setSecondSelectedOption(null);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: dialogTitle,
          subText: dialogSubText,
        }}
        modalProps={{
          isBlocking: false,
          styles: { main: { maxWidth: 450, maxHeight: "auto" } },
          // isDarkOverlay:false
        }}
        styles={(props) => ({ main: { boxShadow: "none" } })} // other props
        className="service-tile-model-dynamic">
        <DialogFooter>
          <>
            {handleDropdowns()}
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              styles={{ root: { marginTop: 10 } }}>
              <PrimaryButton
                aria-label="save"
                onClick={handleSave}
                text={numberOfDropdown === 1 ? "Start" : "Save"}
                disabled={checkIsFieldsrequired()}
                styles={{ root: { width: 100 } }}
              />
              <DefaultButton
                onClick={onCancel}
                aria-label="cancel"
                text="Cancel"
                styles={{ root: { width: 100 } }}
              />
            </Stack>
          </>
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default DynamicDropdownGeneric;
