import {
    Checkbox,
    Dropdown,
    Label,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField,
  } from "@fluentui/react";
  import { useEffect, useState } from "react";
  import React from "react";
  import ".././prompt.css";
  import { ServiceContext } from "@msx/platform-services";
  import {
    postConsumptionAPI,
    //   postConsumptionAPILocal,
  } from "../../../../app/utils/httpUtils";
  import ShimmerLoader from "../../../../app/components/main/Services/ShimmerLoader";
  import PopUpModal from "../../../../app/components/main/Services/PopUpPanel";
  import { setSelectedCategories } from "../../../store";
  import { CATALOG_CATEGORY, ERROR_API_AI, note1, note2, note3, note4, RECOMMENDED_CATEGORY } from "../../../../app/utils/constants";
  
  const V2_Prompt = ({
    Arr,
    loadingPrompt,
    getPrompts,
    setLoadingPrompt,
    Category,
    selectedCategory,
    Upn,
    Area
  }) => {
    const [finalCategory, setFinalCategory] = useState();
    const [dropKey, setDropKey] = useState<any>("");
    const [selectedKey, setSelectedKey] = useState();
    const [promptKey, setPromptKey] = useState("");
    const [prompt, setPrompt] = useState("");
    const [isNewButtonset, setIsNewButtonSet] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [showSubmitted, setShowSubmitted] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [body, setBody] = useState<any>();
    const [errorMessage, setErrorMessage] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const context = React.useContext(ServiceContext);
    const [defaultKeyforPrompt, setDefaultKeyForPrompt] = useState("");
    const [TestClicked, setTestClicked] = useState(false);
    const requiredSearch = "Please Fill the Search Query";
    const [errorSection, setErrorSection] = useState("");
  
    useEffect(() => {
      filterCategorybasedKeys(selectedCategory);
    }, []);
  
    useEffect(() => {
      if (submitted) {
        promptKey !== "" &&
          InsertOrUpdatePrompt().then(() => getPromptsforReload());
      }
    }, [submitted]);
  
    const getPromptsforReload = () => {
      getPrompts();
      setLoadingPrompt(true);
      setPromptKey("");
      setPrompt("");
      setDropKey("");
      setIsNewButtonSet(false);
      setIsDefault(false);
    };
  
    const importantNotes = [note1, note2, note3, note4];
    const filterCategorybasedKeys = (e) => {
      const filteredKeys = Arr?.filter((prompt) => prompt.category === e);
      setDropKey(filteredKeys);
      const defaultKey =
        filteredKeys?.find((prompt) => prompt.isDefault === 1) || filteredKeys[0];
      setSelectedKey(defaultKey?.text);
      setPromptKey(defaultKey?.text);
      setFinalCategory(defaultKey?.category);
      setPrompt(defaultKey?.value);
      setIsDefault(defaultKey?.isDefault === 1);
      setDefaultKeyForPrompt(defaultKey?.text);
      return filteredKeys;
    };
  
    const InsertOrUpdatePrompt = async () => {
      try {
        if (context && context.telemetryClient) {
          const response = await postConsumptionAPI(
            `/api/UserWorkSpace/InsertOrUpdateAiEnvironmentVariable`,
            body,
            context.authClient
          );
          if (response.status > 200 && response.status < 300) {
            alert("saved");
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching data.");
      }
    };
  
    const handlePromptChange = (event, option) => {
      setIsDefault(defaultKeyforPrompt === option?.text);
      setPromptKey(option.text);
      setPrompt(option.value);
    };
  
    const handleNewButton = (isChecked) => {
      setPromptKey("");
      setPrompt("");
      if (isChecked) {
        setIsNewButtonSet(true);
      } else {
        setIsNewButtonSet(false);
      }
      setIsNewButtonSet(!isNewButtonset);
    };
  
    const onNewPromptKey = (event) => {
      setPromptKey(event.target.value);
    };
  
    const onTextareaChange = (event) => {
      if (promptKey !== "") {
        setPrompt(event.target.value);
      } else {
        setErrorMessage("Please fill the New Prompt Key");
      }
    };
  
    const handleCategoryChange = (event, option) => {
      setIsNewButtonSet(false);
      setIsDefault(false);
      setFinalCategory(option.value);
      filterCategorybasedKeys(option.text);
      setShowResults(false);
      setSearchValue("");
    };
  
    const handleDefaultButton = (ev, isChecked) => {
      setIsDefault(isChecked);
    };
  
    const handleTestClicked = async () => {
      setErrorSection("");
      setLoading(true); // Show loader
      setShowResults(false);
      setTestClicked(true);
      sessionStorage.setItem("FinalCategory", finalCategory);
      try {
        let arr = [];
        if (finalCategory === RECOMMENDED_CATEGORY) {
          const apiUrl = "/api/V2_UserWorkSpace/SetReccomendedTile";
          const postData = {
            Upn: Upn,
            Area: Area,
            prompt: prompt,
          };
          const response = await postConsumptionAPI(
            apiUrl,
            postData,
            context.authClient
          );
          if (response.status >= 200 && response.status < 300) {
            // setApiResponse(response.data);
            if (response?.data) {
              response?.data.map((e) => {
                arr.push(e.serviceNames);
              });
              setApiResponse(arr);
            }
            setShowResults(true);
          } else {
            setErrorSection(
              ERROR_API_AI
            );
            setShowResults(true);
          }
        } else if (finalCategory === CATALOG_CATEGORY && searchValue !== "") {
          try {
            if (context) {
              let data = {
                Area: Area,
                prompt: prompt,
              };
  
              const response = await postConsumptionAPI(
                `/api/V2_Service/GetServicesWithAiSearch?query=${searchValue}`,
                data,
                context.authClient
              );
  
              if (
                response?.data &&
                response.status >= 200 &&
                response.status < 300
              ) {
                response?.data.map((e) => {
                  arr.push(e.name);
                });
                setApiResponse(arr);
                setShowResults(true);
                setSearchValue("");
                return response.data;
              } else {
                setErrorSection(
                  ERROR_API_AI
                );
                setShowResults(true);
                throw new Error("Failed to fetch data.");
              }
            }
          } catch (error) {
            console.error(
              "An error occurred while fetching AI-based search data.",
              error
            );
          }
        } else {
          setShowResults(false);
        }
      } catch (error) {
        setShowResults(false);
      } finally {
        setLoading(false); // Hide loader after API completes
      }
    };
  
    const [showDefault, setShowDefault] = useState(false);
    const onsubmit = () => {
      sessionStorage.setItem("FinalCategory", finalCategory);
      const data = {
        promptKey: promptKey,
        promptValue: prompt,
        category: finalCategory,
        isDefault: isDefault ? 1 : 0,
        // isDefault: isDefault ? 1 : 0,
      };
      setBody(data);
      // isDefault ? setShowDefault(true):
      if (isDefault) {
        setShowDefault(true);
      } else {
        setShowSubmitted(true);
      }
    };
  
    const handleConfirmDefault = () => {
      setShowSubmitted(true);
      setShowDefault(false);
    };
  
    const handleCancelDefault = () => {
      setShowSubmitted(false);
      setShowDefault(false);
    };
  
    const ConfirmSubmit = () => {
      setSubmitted(true);
      setShowSubmitted(false);
      // promptKey !== "" && InsertOrUpdatePrompt(data).then(() => getPrompts());
      // setSubmitted(false);
    };
  
    return (
      <>
        <Stack verticalAlign="center" className="mainContainer">
          {loadingPrompt && <ShimmerLoader count={2} />}
          {!loadingPrompt && (
            <Stack
              style={{
                alignItems: "baseline",
              }}
              verticalAlign="center">
              <h3 className="Heading"> Prompt Configuration</h3>
  
              {/* Dropdown s  */}
              <Stack horizontal horizontalAlign="space-between">
                {isNewButtonset ? (
                  <Stack verticalAlign="baseline">
                    <Label styles={{ root: { fontSize: "Large !important" } }}>
                      New Prompt
                    </Label>
                    <TextField
                      rows={1}
                      multiline
                      value={promptKey}
                      placeholder="Fill the Key for the new Prompt"
                      className="promptNewKey"
                      onChange={onNewPromptKey}
                      styles={{
                        field: {
                          "::placeholder": { fontSize: "large" },
  
                          fontSize: "large !important",
                        },
                        root: {
                          width: "35rem !important",
                          marginRight: "2rem",
                        },
                      }}
                    />
                  </Stack>
                ) : (
                  <Dropdown
                    options={dropKey}
                    defaultSelectedKey={selectedKey}
                    onChange={handlePromptChange}
                    label="Select Prompt"
                    styles={{
                      root: { width: "34rem", marginRight: "2rem" },
                      title: {
                        fontSize: "Larger",
                        height: "4rem",
                      },
                      label: {
                        fontSize: "Large !important",
                      },
                      caretDown: {
                        fontSize: "Large",
                        marginTop: "1rem",
                        marginRight: "1rem",
                      },
                    }}
                  />
                )}
                <Dropdown
                  options={Category}
                  defaultSelectedKey={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Category"
                  className="categoryDropdown"
                  styles={{
                    root: { width: "34rem" },
                    title: {
                      fontSize: "Larger",
                      height: "4rem",
                    },
                    label: {
                      fontSize: "Large !important",
                    },
                    caretDown: {
                      fontSize: "Large",
                      marginTop: "1rem",
                      marginRight: "1rem",
                    },
                  }}
                />
              </Stack>
  
              {/* ------checkbox */}
              <Stack
                horizontal
                horizontalAlign="center"
                className="horizontalContainer">
                <Checkbox
                  onChange={handleNewButton}
                  label="New Prompt"
                  styles={{
                    checkbox: {
                      height: "2rem",
                      width: "2rem",
                      marginLeft: "2rem",
                      marginTop: "0.5rem",
                    },
                    checkmark: { fontWeight: "bold", fontSize: "Large" },
                    label: { fontWeight: "700" },
                    text: { fontSize: "Larger", marginTop: "1rem" },
                  }}
                />
  
                <Checkbox
                  onChange={handleDefaultButton}
                  checked={isDefault}
                  label="Default (Check This To Make Prompt  Default)"
                  styles={{
                    checkbox: {
                      height: "2rem",
                      width: "2rem",
                      marginLeft: "2rem",
                      marginTop: "0.5rem",
                    },
                    checkmark: { fontWeight: "bold", fontSize: "Large" },
                    label: { fontWeight: "700" },
                    text: { fontSize: "Larger", marginTop: "1rem" },
                  }}
                />
              </Stack>
  
              {finalCategory === CATALOG_CATEGORY && (
                <>
                  <Label
                    styles={{
                      root: {
                        fontSize: "Large !important",
                        marginTop: "1rem",
                      },
                    }}>
                    Search Query
                    <span
                      className=""
                      style={{
                        marginLeft: "2rem",
                        fontSize: "x-small",
                        fontWeight: "700",
                        color: "Red",
                      }}>
                      {(promptKey === null || promptKey === "") && errorMessage}
                      {searchValue === "" && TestClicked && requiredSearch}
                    </span>
                  </Label>
                  <TextField
                    rows={3}
                    multiline={true}
                    value={searchValue}
                    required
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearchValue(e.target.value)
                    }
                    placeholder="Type to search"
                    styles={{
                      root: { width: "50%" },
                      field: { fontSize: "large !important" },
                    }}
                  />
                </>
              )}
              {/* ------Prompt value */}
              <Label
                styles={{
                  root: {
                    fontSize: "Large !important",
                    marginTop: "2rem",
                    marginBottom: "-2rem",
                  },
                }}>
                Prompt
                <span
                  className=""
                  style={{
                    marginLeft: "2rem",
                    fontSize: "x-small",
                    fontWeight: "700",
                    color: "Red",
                  }}>
                  {(promptKey === null || promptKey === "") && errorMessage}
                </span>
              </Label>
              <TextField
                rows={5}
                multiline={true}
                className="PromptTextArea"
                value={prompt}
                onChange={onTextareaChange}
                styles={{
                  field: {
                    "::placeholder": { fontSize: "large" },
                    fontSize: "large !important",
                  },
                  root: {
                    fontSize: "Large !important",
                    width: "100% !important",
                  },
                }}
                placeholder="Here You can modify your prompt"
              />
              <Stack
                styles={{ root: { paddingTop: "1.5rem", paddingLeft: "1rem" } }}>
                <h6>Important Guidelines</h6>
                {importantNotes.map((note, index) => (
                  <Text
                    key={index}
                    styles={{
                      root: {
                        fontSize: "x-small",
                        fontWeight: "Bold",
                        color: "red",
                      },
                    }}>
                    {index + 1}. {note}
                  </Text>
                ))}
              </Stack>
              <Stack
                horizontal
                horizontalAlign="end"
                tokens={{ childrenGap: 10 }}
                style={{ width: "100%", marginTop: "-5rem" }}>
                <Stack>
                  <PrimaryButton
                    text="Submit"
                    className="promptSubmit"
                    onClick={onsubmit}
                    disabled={prompt === "" || promptKey === ""  || loading}
                    styles={{ label: { fontSize: "x-large" } }}
                  />
                  <Label>
                    <span
                      className=""
                      style={{
                        marginLeft: "1rem",
                        fontSize: "x-small",
                        fontWeight: "700",
                        color: "Grey",
                      }}>
                      Click to make default prompt
                    </span>
                  </Label>
                </Stack>
  
                <Stack>
                  <PrimaryButton
                    text="Test"
                    className="promptTest"
                    onClick={handleTestClicked}
                    disabled={prompt === "" || promptKey === "" || loading}
                    styles={{ label: { fontSize: "x-large" } }}
                  />
                  <Label>
                    <span
                      className=""
                      style={{
                        marginLeft: "1rem",
                        fontSize: "x-small",
                        fontWeight: "700",
                        color: "Grey",
                      }}>
                      Click to Test the scenario
                    </span>
                  </Label>
                </Stack>
              </Stack>
              {loading && (
                <Stack
                  verticalAlign="center"
                  styles={{ root: { alignItems: "center" } }}>
                  {" "}
                  <Spinner
                    size={SpinnerSize.large}
                    label="Loading..."
                    ariaLive="assertive"
                  />
                </Stack>
              )}
              {/* Results Section */}
              {showResults && apiResponse && errorSection === "" && (
                <Stack
                  className="resultSection"
                  style={{ margin: "2rem 0", padding: "1rem" }}>
                  <h4 style={{ fontSize: "large", fontWeight: "600" }}>
                    Result Details for {finalCategory}
                  </h4>
                  <p>
                    <strong>UPN:</strong> {Upn}
                  </p>
                  <p>
                    <strong>Area:</strong> {Area}
                  </p>
                  <p>
                    <strong>Response:</strong>{" "}
                    {JSON.stringify(apiResponse, null, 2)}
                  </p>
                </Stack>
              )}
  
              {showResults && errorSection && (
                <Stack
                  className="resultSection"
                  style={{ margin: "2rem 0", padding: "1rem" }}>
                  <h4 style={{ fontSize: "large", fontWeight: "600" }}>
                    {errorSection}
                  </h4>
                </Stack>
              )}
  
              {/* ------ */}
              <PopUpModal
                isVisible={showSubmitted}
                onConfirm={ConfirmSubmit}
                onCancel={ConfirmSubmit}
                onHide={ConfirmSubmit}
                dialogTitle={"Prompt Change Confirmation"}
                dialogSubText={"Your Request Is Submitted and Prompt is Changes"}
                buttonsCount={1}
                buttonTextOne={"OK"}
              />
              <PopUpModal
                isVisible={showDefault}
                onConfirm={handleConfirmDefault}
                onCancel={handleCancelDefault}
                onHide={handleCancelDefault}
                dialogTitle={"Setting to Default Confirmation"}
                dialogSubText={
                  "Are you sure you want to set  this prompt as default"
                }
                buttonsCount={2}
                buttonTextOne={"Yes"}
                buttonTextTwo={"No"}
              />
  
              {/* </Stack> */}
            </Stack>
          )}
        </Stack>
      </>
    );
  };
  export default V2_Prompt;
  