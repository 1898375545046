import { ISelfHelpContext } from '@iris/react-webchat';

export class IRISBotSettings
{
    static readonly APP_NAME= "mcapsv2";
    static readonly PLATFORM_NAME= "azureapps";
    static readonly CREATE_SUPPORT_TICKET="create support ticket";
    static readonly WORKSTREAM="General Support Ticket";
    static readonly CHATBOT_AVATAR_ICON_URL= "https://iris-cdn.azureedge.net/iris-ux/assets/images/iris-copilot-icon.svg";
};

export const getIrisSelfHelp =(irisContext:any,currentUserData:any,workstream?:string,searchTerm?:string,formDataToIris?:any,tileName?:any, finalTileObject?:any,segment?:any,subsegment?:any):ISelfHelpContext=>{

    if (irisContext===IRISBotSettings.CREATE_SUPPORT_TICKET){

        if(tileName !== "" && tileName !== null)
        {
            return {
              selfHelpEventName: "proactivecopilot",
              messages: [irisContext],
              activityContext: {
                "area": currentUserData.userAreaName,
                "areaOfInterest": currentUserData.userRoleName?currentUserData.userRoleName:"Other",
                "segment": currentUserData.userSegmentName?currentUserData.userSegmentName: finalTileObject["Segment"] ? finalTileObject["Segment"] : segment,
                "subsegment": currentUserData.userSubSegmentName?currentUserData.userSubSegmentName: finalTileObject["SubSegment"] ? finalTileObject["SubSegment"] :  subsegment,
                "Name":workstream,
                "ShortDescription": searchTerm,
                "tileName": tileName===null?"":tileName
              }
            }
        }
        else
        {
          return {
            selfHelpEventName: "proactivecopilot",
            messages: [irisContext],
            activityContext: {
              "area": currentUserData.userAreaName,
              "areaOfInterest": currentUserData.userRoleName?currentUserData.userRoleName:"Other",
              "segment": currentUserData.userSegmentName?currentUserData.userSegmentName: finalTileObject["Segment"] ? finalTileObject["Segment"] :  segment,
              "subsegment": currentUserData.userSubSegmentName?currentUserData.userSubSegmentName: finalTileObject["SubSegment"] ? finalTileObject["SubSegment"] : subsegment,
              "Name":workstream,
              "ShortDescription": searchTerm       
            }
          }
        }
      
    }
    
    return {
    selfHelpEventName: "proactivecopilot",
    messages: finalTileObject["sendServiceGroup"] ? [finalTileObject["IrisUtterance"]] : [irisContext],
    activityContext: {
      "area": currentUserData.userAreaName,
      "areaOfInterest": currentUserData.userRoleName?currentUserData.userRoleName:"Other",
      "segment": currentUserData.userSegmentName?currentUserData.userSegmentName: finalTileObject["Segment"] ? finalTileObject["Segment"] : segment,
      "subsegment": currentUserData.userSubSegmentName?currentUserData.userSubSegmentName: finalTileObject["SubSegment"] ? finalTileObject["SubSegment"] : subsegment,
      "formFieldValues":formDataToIris,
      "tileName": tileName===null?"":tileName, 
      "serviceGroup": finalTileObject["sendServiceGroup"] ? finalTileObject["defaultServiceGroup"] : ""
    }
  }
}




