import React, { useState, useEffect } from "react";
import { getConsumptionAPI, putConsumptionAPI } from "../../../utils/httpUtils";
import { ServiceContext } from "@msx/platform-services";
import { injectIntl } from "react-intl";
import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  DefaultButton,
  Dropdown,
  Stack,
  Checkbox,
  Text,
  TextField,
  Label,
  Toggle
} from "@fluentui/react";
import { InjectedIntlProps } from "react-intl";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import "./V2_EditProfile.css";

import * as Constants from "../../../utils/constants";
import {
  setAddTile,
  setPreferenceChanged,
  setUserUpdated,
} from "../../../../core/store";
import { useDispatch, useSelector } from "react-redux";

interface OwnProps extends InjectedIntlProps {
  isEditableProfile: boolean;
  currentUserData: any;
  getUserIDByEmailId: any;
  onDismissOpenedPanel: any;
  handleChatBotIconClick: any;
}

type Props = OwnProps & RouteComponentProps;
const ProfileComponent: React.FC<Props> = (props) => {
  const [area, setArea] = React.useState();
  const [areaName, setAreaName] = React.useState();
  const context = React.useContext(ServiceContext);
  //const [IsSaveButtonDisable, setSaveButtonDisable] = useState(true);
  const reduxDispatch = useDispatch();
  useEffect(() => {
    if (props.currentUserData !== undefined) {
      setArea(props.currentUserData.userArea);
      setAreaName(props.currentUserData.userAreaName);
      setAiServicesEnabled(props.currentUserData?.isAiServicesEnabled);
    }
  }, [props.currentUserData]);

  useEffect(() => {
    const shouldDisable = !area;
    //setSaveButtonDisable(shouldDisable);
  }, [area]);

  let updateUser = async (e) => {
    try {
      const apiUrl = "/api/V2_User/Update";
      const putData = {
        UserArea: area ? area : props.currentUserData.userArea,
        UserADGroupID: [
          {
            userADGroupID: 1,
          },
        ],
        DataverseRowID: props.currentUserData.dataverseRowID,
        IsActive: props.currentUserData.isActive,
        IsWelcomeMessage: null,
        IsAiServicesEnabled: aiServicesEnabled,
        IsAreaSupportEnabled: areahelpandsupportToggle
      };

      const response = await putConsumptionAPI(
        apiUrl,
        putData,
        context.authClient
      );
      if (response.status >= 200 && response.status < 300) {
        props.onDismissOpenedPanel(e);

        reduxDispatch(setPreferenceChanged(true));
        setSuccessMessage();
        reduxDispatch(setUserUpdated(true));
        props.getUserIDByEmailId();
      }
    } catch (error) {
      console.error("An error occurred while fetching api.", error);
    }
  };

  const setSuccessMessage = () => {
    const messagediv = document.getElementById("actmessages");
    const messagetext = document.getElementsByClassName("message-text");
    messagetext[0].innerHTML = Constants.USER_UPDATE_DIALOG_CONTENT.subText;
    messagediv.style.display = "block";
    const timeoutId = setTimeout(() => {
      messagediv.style.display = "none";
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  const attachButtonClick = () => {
    let elementsWithClass = document.querySelector(
      "button[aria-label='Profile'][value='Profile']"
    );
    elementsWithClass.classList.remove("is-checked");
  };

  const handleSave = (e) => {
    if (area) {
      updateUser(e);
      props.onDismissOpenedPanel(e);
      props.handleChatBotIconClick();
      attachButtonClick();
      reduxDispatch(setAddTile(false));
      reduxDispatch(setAddTile(false));
    }
  };

  const handleCancel = (e) => {
    setArea(null);
    props.onDismissOpenedPanel(e);
    attachButtonClick();
  };

  const [aiServicesEnabled, setAiServicesEnabled] = useState(
    props?.currentUserData?.isAiServicesEnabled
  );
  const handleEnableAIChange = (event, checked) => {
    setAiServicesEnabled(checked);
  };

  const [areahelpandsupportToggle, setareahelpandsupportToggle] = useState(
    props?.currentUserData?.isAreaSupportEnabled
  );
  const handleOnChangeareahelpandsupport = (event, areahelpandsupportToggle?: boolean) => {
    setareahelpandsupportToggle(!!areahelpandsupportToggle);
  };


  const renderMain = (): JSX.Element => {
    return (
      <div className="editPrfofileContainer ">
        <Stack
          horizontalAlign="start"
          verticalAlign="start"
          tokens={{ childrenGap: 20 }}
          className="editProfile">
          <form
            style={{
              minHeight: "275px",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: "200",
              lineHeight: "36px",
              letterSpacing: "0px",
              textAlign: "left",
            }}>
            <h3 style={{ fontSize: "16px", fontWeight: "600" }}>
              {" "}
              My Profile
            </h3>
            <Label className="hover-label">Area - {areaName}</Label>
            <Stack tokens={{ childrenGap: 16 }}>
              <div className="toggle-container">
                <Label >Show Help & Support for my Area</Label>
                <Toggle
                  checked={areahelpandsupportToggle}
                  onChange={handleOnChangeareahelpandsupport}
                />
              </div>
              <MessageBar
                messageBarType={MessageBarType.info}
                isMultiline={true}
                styles={{ root: { marginTop: "10px" } }}>
                {Constants.ENABLE_AREATOGGLE_NEWPROFILESETTING}
              </MessageBar>
              {/* {props.currentUserData?.isAiUser && (
                <>
                  <Checkbox
                    label={"Enable AI services"}
                    styles={{ root: { marginTop: "10px" } }}
                    checked={aiServicesEnabled} // bind to Redux state
                    onChange={handleEnableAIChange} // handle change
                  />

                  <MessageBar
                    messageBarType={MessageBarType.warning}
                    isMultiline={true}
                    styles={{ root: { marginTop: "10px" } }}>
                    {Constants.ENABLE_AI_SERVICES_MESSAGE}
                  </MessageBar>


                </>
              )} */}

              <div className="profilebutton" style={{ float: "left" }}>
                <Stack
                  horizontal
                  horizontalAlign="end"
                  tokens={{ childrenGap: 10 }}
                  style={{ float: "left" }}>
                  <PrimaryButton
                    className="popup-save-edit"
                    text="Save"
                    //disabled={IsSaveButtonDisable}
                    onClick={(e) => handleSave(e)}
                    styles={{
                      root: { fontFamily: "Segoe UI", marginTop: "16px" },
                    }}
                  />

                  <DefaultButton
                    className="popup-reset"
                    text="Cancel"
                    styles={{
                      root: {
                        fontFamily: "Segoe UI",
                        paddingLeft: "10px",
                        marginTop: "16px",
                      },
                    }}
                    onClick={(e) => handleCancel(e)}
                  />
                </Stack>
              </div>
            </Stack>
          </form>
        </Stack>
      </div>
    );
  };

  return renderMain();
};

export const V2_EditProfile = withRouter(injectIntl(ProfileComponent));
