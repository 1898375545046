export const IDWebRequestLink =
  "https://idweb.microsoft.com/IdentityManagement/aspx/Groups/AllGroups.aspx?searchtype=3a8f0ff2-72d2-428b-8e5b-2b3653cbca8e&content=";
export const SUCCESS_DIALOG_CONTENT = {
  title: "Success",
  subText: "Service added to workspace successfully.",
};

export const USER_UPDATE_DIALOG_CONTENT = {
  title: "Success",
  subText: "User details updated successfully.",
};

export const FEEDBACK_ADDED = {
  title: "Success",
  subText: "Thank You! Feedback Submitted Successfully.",
};

export const ALL_SERVICES_LABEL = "All Help";

export const ADD_TO_WORKSPACE_BUTTON_TEXT = "Add to My Help Workspace";

export const GO_TO_WORKSPACE_TEXT = "Go to My Help Workspace";

export const NO_SERVICES_LEFT_MESSAGE = "No services to add.";

export const MICROSOFT_PRIVACY_STATEMENT =
  "https://privacy.microsoft.com/en-us/privacystatement";

export const MICROSOFT_DATA_PRIVACY_NOTICE = "https://privacy.microsoft.com/en-us/data-privacy-notice";

export const NO_DATA_AVAILABLE =
  "Unable to fetch details. Please try again after sometime.";

export const TICKET_ESCALATION_MESSAGE =
  " If you need to escalate a ticket, take action by typing 'Escalate' in Copilot";

export const DEFAULT_AMERICAS_PREFERENCE = [
  "United States",
  "Seller",
  "Enterprise",
];
export const WEBFORM_SERVICE_NAMES = [
  "Employee Assignment Requests",
  "Account Management Requests",
  "Awards-Contests and Incentive Requests",
  "Revenue Requests",
  "Partner Related Requests",
  "Quota and Incentive Compensation Requests",
  "Field BI and Enablement Requests",
  "Revenue Requests (Admin)",
  "Co-Sell",
  "Account Management (AM Tool)"
];
export const WEBFORM_IRIS_UTTERANCE = "Americas Requests";
export const WEBFORM_COSELL_IRIS_UTTERANCE = "Co-Sell";
export const WEBFORM_GEM_IRIS_UTTERANCE = "ARC Requests";
export const DisableIRIS_DWR="Digital Win Room (DWR) Help";
export const MCEM_STAGE = "MCEM Stage";
export const MCEM_AGNOSTIC = "MCEM Agnostic";

export const MODE_QUERY = ["incident"];
export const ALLOWED_PARAMETERS = ["tilename", "irisutterance", "area", "mode","downloadfile","typeoffeedback","segment","subsegment","tileinfo", "sendservicegroup", "defaultservicegroup"];
export const INCIDENT_TILE_UTTERANCE = "create support ticket";
export const TILE_NAME = "tilename";
export const TILE_INFO = "tileinfo";
export const SEND_SERVICE_GROUP = "sendservicegroup";
export const DEFAULT_SERVICE_GROUP = "defaultservicegroup";
export const DOWNLOAD_FILE = "downloadfile"
export const FILE_TYPE = ["screenshot","video","file"]
export const IRIS_UTTERANCE = "irisutterance";
export const AREA = "area";
export const MODE = "mode";
export const SEGMENT = "segment";
export const SERVICE_LOCKED ="This is a private service and hence it is not accessible";
export const SERVICE_NOT_IN_PREFERENCE_NonAmericas="This Service is not available for the selected preferences. Please select the correct preferences to view the service.";
export const V2_SERVICE_NOT_IN_PREFERENCE_NonAmericas="Please note, the service or incident in question is not handled by MCAPSHelp. For the correct support, reach out directly to MCAPSHelpV2Support@microsoft.com.";
export const SERVICE_NOT_IN_PREFERENCE_Americas="This form is not available on the workspace. Please add the service by selecting the user preference as Americas HQ, Seller, Enterprise, N/A.";
export const V2_SERVICE_NOT_IN_PREFERENCE_Americas="Please note, the service or incident in question is not handled by MCAPSHelp. For the correct support, reach out directly to MCAPSHelpV2Support@microsoft.com.";
export const SERVICE_NOT_VALID="Please note, the service or incident in question is not handled by MCAPSHelp. For the correct support, reach out directly to MCAPSHelpV2Support@microsoft.com.";
export const USER_NOT_PART_OF_FEEDBACK_GROUP="Please note, You are not authorized to download the feedback. For the correct support, reach out directly to MCAPSHelpV2Support@microsoft.com.";
export const FORM_NAMES_API_ENDPOINT = `/api/Services/GetServicesFormMapping`;
export const V2_FORM_NAMES_API_ENDPOINT = `/api/V2_Service/GetServicesFormMapping`;
export const SERVICE_NOT_IN_WORKSPACE = "This form is not available on the workspace. Please add the service by selecting the user preference as Americas HQ, Seller, Enterprise, N/A.";
export const ECIF_STATUS_MESSAGE=  `<p>Click <a href='https://usbso.microsoft.com/EmployeeAssignment' target='_blank'>here</a> to view your ECIF requests in OneAsk.To learn more about the status of your request, reference the <a href='https://microsoft.sharepoint.com/teams/WCBECIFStrategicInitiatives/SitePages/OneAsk.aspx' target='_blank'>OneAsk Status decoder.</a></p>`;
export const ECIF_PO_STATUS_MESSAGE=`<P>To view a more detailed status of your PO,<a href='https://microsoft.sharepoint.com/:p:/t/ECIFInfoPediaFIles/EcEC5yVizlNFuGeFen49v2kBBMxe-nCSqiZgOacK8g7wSg?e=PtI1B6' target='_blank' >click here</a>. Reminder: it’s the requester’s responsibility to ensure that the correct SAFE approvers are in place. For information on how to check and request SAFE approvals, please reference the <a href='https://microsoft.sharepoint.com/:p:/t/ECIFInfoPediaFIles/EcEC5yVizlNFuGeFen49v2kBBMxe-nCSqiZgOacK8g7wSg?e=PtI1B6' target='_blank'>MS Authorize Guide.</a> </P>`;
export const AI_Tile_Telematry = "AI Service Tile"
export const Service_Tile_Telematry ="Service Tile"
export const AI_Telematry_message = "User click on AI Service Tile"
export const NON_AI_Telematry_message ="User click on Service Tile"
export const ai_servie_detail_panel_cancel = "AI Service Details Panel Cancel"
export const non_ai_service_detail_panel_cancel = "Service Details Panel Cancel"
export const ai_service_detail_cancel_message = "AI User click on Service Details Panel Cancel"
export const non_ai_service_detail_cancel_message = "User click on Service Details Panel Cancel"
export const AI_EMPTY_LABEL = "Currently, there are no AI recommendations tailored to your profile"
export const ENABLE_AI_SERVICES_MESSAGE = "Activating this feature will introduce AI-driven recommendations to the Homepage and catalog page. Note that AI-generated content may not be precise."
export const PREF_EDIT_WARNING = "The Services already added in My Help Workspace will be affected and reset when you change the preferences."
export const note1 = `Results may vary as the data is sourced from an AI system, which might occasionally provide out-of-scope results.`
export const note2 = `Ensure that {serviceData} is clearly specified as the source of information within the prompt. This helps guide the assistant accurately.`
export const note3 = `Mention the output format explicitly, e.g., Service: service1, to ensure the results are structured as expected.`
export const note4 = `For testing purposes, only the service names will be displayed, without any additional information`
export const NOT_AI_Authorised =`You do not have the necessary permissions to view this page.`
export const NOT_AI_AUTHORISED_TWO =`Please contact your administrator for assistance or verify your access rights.`
export const ERROR_API_AI =`Apologies for the inconvenience. The AI Service is currently unable to process your  request.`
export const RECOMMENDED_CATEGORY = 'Ai-RecommendedServices'
export const CATALOG_CATEGORY = 'AI-CatalogSearch'
export const isProfileOld = false
export const ENABLE_AREATOGGLE_NEWPROFILESETTING = "The Help & support services in the catalog are personalised to your area and you can see the entire catalog by switching this button"