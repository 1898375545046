import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
import { Icon, Stack, Text } from "@fluentui/react";
import {
  getConsumptionAPI,
  getConsumptionAPILocal,
  postConsumptionAPI,
} from "../../../utils/httpUtils";
import { ServiceContext } from "@msx/platform-services";
import ".././CatalogPage.css";
import { InjectedIntlProps, injectIntl } from "react-intl";
import ShimmerLoader from "../../main/Services/ShimmerLoader";
import ErrorComponent from ".././ErrorComponent";
import * as Constants from "../../../utils/constants";
import {
  SETChileComponentName,
  SETHasServiceRequestTypeLogData,
  SETIsCustomeLog,
  SETLogEventData,
  SETServiceRequestTypeLogData,
  getSelectedCategories,
  setAddTile,
  setIsLoader,
} from "../../../../core/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { lazyRetry } from "../../../utils/utils";
const Tile = lazy(() => lazyRetry(() => import("./V2_TileComponent")));
const SearchComponent = lazy(() =>
  lazyRetry(() => import("./V2_SearchComponent"))
);
const FilterLeftPanel = lazy(() =>
  lazyRetry(() =>
    import("../../filterPanelCatalog/V2component_Filter/V2_FilterPanel").then(
      (module) => ({ default: module.V2_FilterLeftPanel })
    )
  )
);

export interface CatalogPageProp extends InjectedIntlProps {
  setActiveTabKey?: any;
  setIrisContext?: any;
  userId: any;
  setAddServiceId: any;
  resetCatalogIrisContext: any;
  catalogServicesResponse: any;
  setServicesAdded: any;
  isLoading: any;
  setSearchTerm?: any;
  setSingleServiceAdded: any;
  handleIrisLoading: any;
  currentUserData: any;
}

// CatalogPage Component
const CatalogComponent: React.FC<CatalogPageProp> = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [dataTile, setDataTile] = useState([]);
  const [originalDataTile, setOriginalDataTile] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [resultError, setResultError] = useState("");
  const [errorData, setErrorData] = useState([]);
  const userId = props.userId;
  const context = useContext(ServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const SelectedCategoriesData = useSelector(getSelectedCategories);

  const reduxDispatch = useDispatch();
  useEffect(() => {
    reduxDispatch(setIsLoader(false));
    const fetchData = async () => {
      try {
        if (context) {
          setIsLoading(true);
          // Fetch data from parent shell component
          const response = props.catalogServicesResponse;
          if (response?.data) {
            let result = checkResponse(response);
            if (result === "success") {
              setResultError("success");
              setOriginalDataTile(response.data);
              setDataTile(response.data);
            } else {
              setResultError("error");
              setErrorData(response.data);
            }
          } else {
            throw new Error("Failed to fetch data.");
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching data.", error);
      } finally {
        setIsLoading(false);
      }
    };

    props.setServicesAdded(false);
    fetchData();
  }, [props.catalogServicesResponse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchAllAreas();
  }, []);

  //   const Areas = [
  //     { key: 50, ID: 50, text: "Option 1", name: "Option 1" },
  //     { key: 2, ID: 2, text: "Option 2", name: "Option 2" },
  //     { key: 3, ID: 3, text: "Option 3", name: "Option 3" },
  //   ];

  const [Areas, setAreas] = useState();

  const fetchAllAreas = async () => {
    try {
      if (context && context.authClient) {
        const response = await getConsumptionAPI(
          "/api/V2_Area/GetAll",
          context.authClient
        );
        if (response?.status === 200 && response?.data) {
          let data = response.data.map((item) => ({
            key: item.id,
            ID: item.id,
            text: item.name,
            name: item.name,
          }));
          setAreas(
            data
          );
        }
      }
    } catch (Exception) {
      console.error(Exception);
    }
  };

  // Function to check the response status
  const checkResponse = (response) => {
    switch (response.status) {
      case 200:
      case 201:
      case 204:
        return "success";
      case 401:
      default:
        return "error";
    }
  };

  // Function to set the active tab key in Catalog
  const setActiveTabKeyinCatalog = () => {
    props.setActiveTabKey("0");
  };

  // Event handler for navigating to the dashboard
  const handleGoToDashboardButtonClick = () => {
    setActiveTabKeyinCatalog();
    setChatBotContextFromCatalog("create support ticket");
    props.handleIrisLoading(false);

    // Start Event Logging //
    reduxDispatch(SETChileComponentName("Catalog"));

    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(
      SETLogEventData({
        elementName: "Go to My Help Workspace",
        elementId: "",
        action: "click",
        message: `User click on Go to My Help Workspace`,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(
      SETServiceRequestTypeLogData({
        SearchTerm: searchValue,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
  };

  // Function to set the Iris context from Catalog
  const setChatBotContextFromCatalog = (irisContext) => {
    props.setIrisContext(irisContext);
    props.setSearchTerm(searchValue);
  };

  // Effect to reset Iris context when needed
  useEffect(() => {
    if (props.resetCatalogIrisContext) {
      props.setIrisContext(null);
    }
  }, [props.resetCatalogIrisContext]);

  // Function to filter suggestions based on the search input
  const filterSuggestions = async (input) => {
    const lowercasedInput = input.toLowerCase();
    let filtered = [];
    if (input.length > 2) {
      try {
        const dataSearch = await getSearchResult(input);
          // props.currentUserData?.isAiUser &&
          // props.currentUserData?.isAiServicesEnabled
          //   ? await getAiSearchResult(input)
          //   : await getSearchResult(input);
        if (dataSearch) {
          const searchNames = dataSearch.map((item) => item.name);
          filtered = dataTile.filter((tile) => searchNames.includes(tile.name));
        } else {
          filtered = dataTile
            .filter((tile) => tile.name.toLowerCase().includes(lowercasedInput))
            .slice(0, 5);
        }
      } catch (error) {
        console.error("Error fetching search data:", error);
      }
      if (filtered.length > 0) {
        return filtered;
      }
    }

    const exactMatch = dataTile.find(
      (tile) => tile.name.toLowerCase() === lowercasedInput
    );
    return exactMatch && input.length > 2 ? [exactMatch] : [];
  };

  // Event handler for search box input
  const handleSearch = async (event) => {
    reduxDispatch(setAddTile(false));
    const newValue = event?.target?.value || "";
    const newSearchValue = newValue ? newValue.toString() : "";
    setSearchValue(newSearchValue);
    // !props.currentUserData?.isAiServicesEnabled
    //   ? setSuggestions(await filterSuggestions(newValue))
    //   : setSuggestions([]);
    setSuggestions(await filterSuggestions(newValue));
    setSelectedSuggestion(null);
    if (!newValue) {
      setSelectedSuggestion(null);
      setDataTile(originalDataTile);
    }
  };

  const filterTilesBySearch = async (searchTerm) => {
    // if (
    //   props.currentUserData?.isAiUser &&
    //   props.currentUserData?.isAiServicesEnabled
    // ) {
    //   // Call AI-based search when AI services are enabled
    //   getAiSearchResult(searchTerm).then((aiSearchData) => {
    //     if (
    //       aiSearchData?.message !==
    //       "No services found matching your search criteria."
    //     ) {
    //       const searchIds = aiSearchData.map((item) => item.id);
    //       const filteredTiles = dataTile.filter((tile) =>
    //         searchIds.includes(tile.id)
    //       );
    //       console.log(filteredTiles);
    //       setDataTile(filteredTiles);
    //       setSuggestions(filteredTiles);
    //       //setSuggestions([]);
    //     }
    //   });
    // } else {
      // Default search result
      getSearchResult(searchTerm).then((dataSearch) => {
        if (dataSearch) {
          const searchIds = dataSearch.map((item) => item.id);
          const filteredTiles = dataTile.filter((tile) =>
            searchIds.includes(tile.id)
          );
          console.log(filteredTiles);
          setDataTile(filteredTiles);
          setSuggestions([]);
        }
      });
    //}
  };

  const getSearchResult = async (query) => {
    try {
      setIsLoading(true);
      if (context) {
        let data = {
          mcemFilter:
            SelectedCategoriesData["MCEM Stage"] &&
            SelectedCategoriesData["MCEM Stage"].length > 0
              ? SelectedCategoriesData["MCEM Stage"].toString()
              : null,
          categoryFilter:
            SelectedCategoriesData["Category"] &&
            SelectedCategoriesData["Category"].length > 0
              ? SelectedCategoriesData["Category"].toString()
              : null,
        };

        const response = await postConsumptionAPI(
          `/api/V2_Service/GetAllServicesWithSearch/${userId}?query=${query}`,
          data,
          context.authClient
        );
        if (response?.data) {
          setIsLoading(false);
          return response.data;
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data.");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching data.", error);
    }
  };

  const getAiSearchResult = async (query) => {
    try {
      setIsLoading(true);
      if (context) {
        let data = {
          Area: props.currentUserData?.userAreaName,
          Role: props.currentUserData?.userRoleName,
          Segment: props.currentUserData?.userSegmentName,
          Subsegment: props.currentUserData?.userSubSegmentName,
          // McemFilter: SelectedCategoriesData["MCEM Stage"] && SelectedCategoriesData["MCEM Stage"].length > 0 ? SelectedCategoriesData["MCEM Stage"].toString() : null,
          // CategoryFilter: SelectedCategoriesData["Category"] && SelectedCategoriesData["Category"].length > 0 ? SelectedCategoriesData["Category"].toString() : null,
        };

        const response = await postConsumptionAPI(
          `/api/Services/GetServicesWithAiSearch?query=${query}`,
          data,
          context.authClient
        );
        if (response?.data) {
          setIsLoading(false);
          return response.data;
        } else {
          setIsLoading(false);
          throw new Error("Failed to fetch data.");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error(
        "An error occurred while fetching AI-based search data.",
        error
      );
    }
  };

  const handleOnSearch = async (newValue) => {
    // Start Event Logging //
    reduxDispatch(SETChileComponentName("Catalog"));

    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(
      SETLogEventData({
        elementName: "Search term searched",
        elementId: "",
        action: "Key Press",
        message: `User Key Press on Search term search`,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(
      SETServiceRequestTypeLogData({
        SearchTerm: newValue,
        profileSetting: `v2`,
        
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //

    await filterTilesBySearch(newValue);
  };

  // Event handler for suggestion click
  const handleSuggestionClick = async (suggestion) => {
    // Start Event Logging //
    reduxDispatch(SETChileComponentName("Catalog"));

    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(
      SETLogEventData({
        elementName: "Search suggestion selection",
        elementId: "",
        action: "click",
        message: `User click on Search suggestion selection`,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(
      SETServiceRequestTypeLogData({
        ServiceName: suggestion.name,
        RequestType: "",
        TileName: suggestion.tileName,
        IsNonIrisService: suggestion.isNonIRISService ? "true" : "false",
        IrisUtterance: suggestion.iriS_Utterance,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
    setSelectedSuggestion(suggestion);
    setSearchValue(suggestion.name);
    setSuggestions([]);
  };

  const handleSuggestionKeyPress = (e, suggestion) => {
    if (e.key === "Enter") {
      handleSuggestionClick(suggestion);
    }
  };

  const displayTiles = selectedSuggestion ? [selectedSuggestion] : dataTile;
  useEffect(() => {
    const filteredData = originalDataTile.filter((tile) => {
      // Check if the tile matches any selected filter
      return (
        selectedFilters.length === 0 || selectedFilters.includes(tile.category)
      );
    });

    setDataTile(filteredData);
  }, [selectedFilters, originalDataTile]);

  // Function to handle filter selection
  const handleFilterSelect = (filter) => {
    // Toggle filter selection
    reduxDispatch(setAddTile(false));
    const updatedFilters = selectedFilters.includes(filter)
      ? selectedFilters.filter((f) => f !== filter)
      : [...selectedFilters, filter];

    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {}, [SelectedCategoriesData]);

  return (
    <Stack>
      {props.isLoading && <ShimmerLoader count={15} />}
      {!props.isLoading && (
        <Stack>
          <Stack verticalAlign="start">
            <Suspense fallback={<></>}>
              <SearchComponent
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleSearch={handleSearch}
                suggestions={suggestions}
                handleSuggestionClick={handleSuggestionClick}
                handleSuggestionKeyPress={handleSuggestionKeyPress}
                selectedSuggestion={selectedSuggestion}
                handleOnSearch={handleOnSearch}
                isLoading={isLoading}
                currentUserData={props.currentUserData}
              />
            </Suspense>
          </Stack>
          <Stack></Stack>
          {resultError === "success" ? (
            <Suspense fallback={<></>}>
              <Stack horizontal verticalAlign="start">
                <div className="ms-Grid-col ms-lg3 LeftPanelfilter">
                  <FilterLeftPanel
                    selectedFilters={selectedFilters}
                    handleFilterSelect={handleFilterSelect}
                    Areas={Areas}
                    UserArea={props.currentUserData.userArea}
                    IsMyAreaSupportEnabled={props.currentUserData.isAreaSupportEnabled}
                  />
                </div>
                <div className="ms-Grid-col ms-lg9 rightPanelfilter">
                  <div id="v2_Container"></div>
                  <Tile
                    dataTile={displayTiles}
                    userId={userId}
                    setAddServiceId={props.setAddServiceId}
                    setSingleServiceAdded={props.setSingleServiceAdded}
                    currentUserData={props.currentUserData}
                  />
                </div>
              </Stack>
            </Suspense>
          ) : (
            <div
              style={{
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "30%",
                marginRight: 30,
                width: "50%",
              }}>
              <ErrorComponent
                errorData={errorData}
                isWorkspaceValueError={false}
              />
            </div>
          )}

          <hr style={{ margin: "10px 34px 0px 10px" }} />

          <Stack
            className="catalogBottomContainer"
            aria-label="Unable to find what you are looking for?">
            <Text variant="mediumPlus" className="catalogBottomContainer_text">
              Unable to find what you are looking for?
            </Text>
            <button
              className="buttonContainer"
              onClick={handleGoToDashboardButtonClick}
              tabIndex={0}>
              <span>{Constants.GO_TO_WORKSPACE_TEXT}</span>
              <Icon iconName="Forward" className="forwardIcon" />
            </button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export const V2_CatalogPage = injectIntl(CatalogComponent);
