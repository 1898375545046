import React, { useState, useEffect, lazy, Suspense} from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import ErrorBoundary from '../../../errorHandling/errorBoundry';
import { MyContextProvider } from '../../context/myContext';
import "./main.css";
import { useDispatch, useSelector } from 'react-redux';
import { setIsBotError, getBotToken} from '../../../core/store';
import { Tile } from './Services/Tile';
import { lazyRetry } from '../../utils/utils';

const TicketDetailPage = lazy(() =>
  lazyRetry(() =>
    import('./ticketdetails/TicketDetailsRender').then(module => ({ default: module.TicketDetailPage }))
  )
);

const Workspace = lazy(() =>
  lazyRetry(() =>
    import('./Workspace/Workspace').then(module => ({ default: module.Workspace }))
  )
);
export interface MyWorkSpaceProp extends InjectedIntlProps {
  setActiveTabKey?: any;
  userId: any;
  setIrisUtterance?: any;
  setTileName: any;
  setIrisAppName?: any;
  isUserLoggedIn: any;
  addServiceId: any;
  setAddServiceId: any;
  setServiceDeleted: any;
  isPageLoad: any;
  userDataChanged: any;
  currentUserData: any;
  setFormDataToIris?: any;
  setShowBot?: any;
  setFormIrisUtterance?: any;
  setChatBotClicked?: any;
  setIsIrisLoading?: any;
  tileName?: any;
  chatBotClicked?: any;
  irisBotIconClicked?: any;
  showBotinShell?: any;
  finalTileObject?: any;
  setProfileLoading?: any;
  isCanceledPreset?: any;
  showErrorModal?: any;
  setShowErrorModal?: any;
  reccomendedData?:any;
  recommendationLoaded?:any;
  setRecommendationLoaded?:any;
  isAiEnabled: boolean;
}
 
 
const MainComponent: React.FC<MyWorkSpaceProp> = (props) => {
  const getToken = useSelector(getBotToken)
  const reduxDispatch = useDispatch();
  const [isTicketDetailVisible, setIsTicketDetailVisible] = useState(false);
  const [ticketStatus, setTicketStatus] = useState('All');

  useEffect(() => {
    if(getToken===null)
    {
      reduxDispatch(setIsBotError(true));
    }
    window.scrollTo(0, 0);
  }, []);
 
 
  const setActiveTabKeyinMain = (activeKey) => {
    props.setActiveTabKey(activeKey);
  }
 
 
  return (
 
    <div style={{ display: 'flex' }}>
      <div className='workspace'>
        <ErrorBoundary componentName="Workspace" >
          <MyContextProvider message={`workspace_${props.userId}`} >
            {!isTicketDetailVisible &&
            <Suspense
            fallback={
             <>
             </>
            }
          >
            <Workspace
              isPageLoad={props.isPageLoad}
              isUserLoggedIn={props.isUserLoggedIn}
              setIsTicketDetailVisible={setIsTicketDetailVisible}
              setTicketStatus={setTicketStatus}
            />
            </Suspense>
            }
          </MyContextProvider>
        </ErrorBoundary>
        <ErrorBoundary componentName="Services" >
          <MyContextProvider message={`tile_${props.userId}`}>
            {!isTicketDetailVisible &&            
            <Tile
              setActiveTabKey={setActiveTabKeyinMain}
              userId={props.userId}
              setIrisUtterance={props.setIrisUtterance}
              setTileName={props.setTileName}
              isUserLoggedIn={props.isUserLoggedIn}
              addServiceId={props.addServiceId}
              setAddServiceId={props.setAddServiceId}
              setServiceDeleted={props.setServiceDeleted}
              setIrisAppName={props.setIrisAppName}
              userDataChanged={props.userDataChanged}
              currentUserData={props.currentUserData}
              setFormDataToIris={props.setFormDataToIris}
              setShowBot={props.setShowBot}
              setChatBotClicked={props.setChatBotClicked}
              finalTileObject={props.finalTileObject}
              setProfileLoading={props.setProfileLoading}
              isCanceledPreset={props.isCanceledPreset}
              showErrorModal = {props.showErrorModal}
              setShowErrorModal = {props.setShowErrorModal}
              reccomendedData = {props.reccomendedData}
              recommendationLoaded={props.recommendationLoaded}
              setRecommendationLoaded={props.setRecommendationLoaded}
              isAiEnabled={props.isAiEnabled}
            />
            }
          </MyContextProvider>
        </ErrorBoundary>
 
        <ErrorBoundary componentName="TicketDetails">
          <MyContextProvider message={`workspace_${props.userId}`}>
            {isTicketDetailVisible &&
            <Suspense
            fallback={
             <>
             </>
            }
          >
            <TicketDetailPage
              setActiveTabKey={setActiveTabKeyinMain}
              userId={props.userId}
              isUserLoggedIn={props.isUserLoggedIn}
              setIsTicketDetailVisible={setIsTicketDetailVisible}
              status={ticketStatus}
            />
            </Suspense>
            }
          </MyContextProvider>
        </ErrorBoundary>
 
      </div>

       {/* Fake iris End */}
    </div>
  );
};
export const Main = injectIntl(MainComponent);