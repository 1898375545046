import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChatInput,
  ImperativeControlPlugin,
} from "@fluentui-copilot/react-copilot";
import { InjectedIntlProps, injectIntl } from "react-intl";
import type { ImperativeControlPluginRef } from "@fluentui-copilot/react-copilot";
import copilotlogo from './images/iris-copilot-icon.svg';
import {
  CopilotChat,
  Timestamp,
  UserMessageV2 as UserMessage,
  CopilotMessageV2 as CopilotMessage
} from "@fluentui-copilot/react-copilot-chat";
import {
  setIsBotError,
  setIsLoader,
  settitleLoaderName,
} from "../../../core/store";
import {
  Avatar,
  Button,
  Divider,
  makeStyles,
  Radio,
  RadioGroup,
  tokens,
} from "@fluentui/react-components";
import {
  EditRegular,
  BookmarkRegular,
  ShareRegular,
  ImageBorder32Regular,
  DismissRegular,
  ArrowClockwiseRegular
} from "@fluentui/react-icons";
import { ProgressIndicator } from "@fluentui/react";
import type { MessageStreamingData } from "./llmClient";
import { LLMClient } from "./llmClient";
import type { MessageData, PromptData } from "./data";
import { SYSTEM_MESSAGES, SUGGESTIONS } from "./data";
import './Chat.css';

import { formatCurrentTime, updateMessageContent } from "./utils";
import {
  CopilotMessageWrapper,
  SystemMessageWrapper,
} from "./componentRenderers";
import { postExternalConsumptionAPI } from "../../utils/httpUtilsExternalAPI";
import { postConsumptionAPI } from "../../utils/httpUtils";
import { ServiceContext } from "@msx/platform-services";
import { getSubIssueTypes, getIsConversationId, SetIsConversationId, getCurrentConversationId, SetCurrentConversationId, SETIsCustomeLog, SETLogEventData, SETHasServiceRequestTypeLogData, SETChileComponentName } from "../../../core/store";
const useStyles = makeStyles({
  provider: {
    backgroundColor: tokens.colorNeutralBackground3,
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
    borderRadius: tokens.borderRadiusXLarge,
    display: "flex",
    rowGap: "0px",
    flexDirection: "column",
  },
  chat: {
    height: "450px",
    marginBottom: "100px",
    marginTop: "0px",
    padding: `${tokens.spacingVerticalL} ${tokens.spacingHorizontalL}`,
    overflowY: "auto",
    OverflowX: 'hidden',
    width: '100%',
    maxWidth: 'calc(100% - 26%)',
    scrollbarWidth: 'none',
    scrollBehavior: 'smooth'
  },

  inputContainer: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    margin: "0 4px",
  },
  chatInput: {
    width: '100%',
    marginBottom: '36px',
    position: 'fixed',
    bottom: '10px',
    borderTop: 'none !important', // Ensure the top border is removed
    '& .fai-ChatInput__inputWrapper': {
      borderTop: 'none !important', // Specifically target internal elements if needed
    },
    backgroundColor: '#fff',
    maxWidth: 'calc(100% - 28%)',
    borderRadius: '10px',
    "@media (min-width: 768px)": {
      maxWidth: "calc(100% - 28%)", // Adjustable for larger screens
    },
  },
  imagePreviewContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: '8px',
    padding: '7px',
    width: '100%',
    maxWidth: 'max-content',
    backgroundColor: '#f3f3f3'
  },
  chatContainer: {
    backgroundColor: '#fff',
    height: '120px',
    width: '100%',
    borderRadius: '10px',
  },
  imagePreview: {
    maxWidth: "30px",
    maxHeight: "30px",
    borderRadius: tokens.borderRadiusSmall,
  },
  imageName: {
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground2,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-20px",
    justifyContent: 'center',
    width: '100%',
    maxWidth: 'calc(100% - 28%)',
    textAlign: 'center',
    position: 'relative'
  },
  dissmissContainer: {
    position: 'absolute',
    top: '10%',
    display: "flex",
    transform: 'translateY(-50%)',
    marginBottom: "0px",
    justifyContent: 'flex-start',
    marginLeft: '90%'
  },
  logoImage: {
    width: "50px",
    height: "50px",
    marginRight: tokens.spacingHorizontalS,
  },
  logoText: {
    display: "flex",
    flexDirection: "column",
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '5px',
    textAlign: 'center'
  },
  logoPara: {
    fontSize: '18px',
    fontWeight: '600',
    margin: '5px'
  }
});
export interface ChatComponentProp extends InjectedIntlProps {

  setFormDataToIris?: any;
  setShowBot?: any;
  setChatBotClicked?: any;
  setIrisUtterance: any;
  setIrisAppName: any;
  setTileName: any;
  setMessageData?: any;
  setIrisPayload?: any;
  closeCopilot?: any;
  userEmail?: any;
  subIssueTypes?: any;
  refreshCopilot?: any;
}
const ChatComponent: React.FC<ChatComponentProp> = (props) => {
  let irisBot = false;
  const reduxDispatch = useDispatch();
  const sessionStartTime = React.useRef<Date>(new Date());
  // const subIssueTypes = useSelector(getSubIssueTypes);
  const IsConversationCreated = useSelector(getIsConversationId);
  const ConversationId = useSelector(getCurrentConversationId);
  const [messageHistory, setMessageHistory] = React.useState<MessageData[]>([
    {
      type: "copilot",
      id: "copilot-0",
      message: "Welcome to Copilot!",
      timestamp: formatCurrentTime(),
    }
  ]);

  const [isfetchingAiResponse, setIsfetchingAiResponse] = React.useState(false);
  const [subIssueSelected, setSubIssueSelected] = React.useState(false);
  const [isShoeSubIssue, setIsShowSubIssue] = React.useState(false);
  const [indicatorLabel, setIndicatorLabel] = React.useState("Getting AI response...");
  const controlRef = React.useRef<ImperativeControlPluginRef>(null);

  const [inputText, setInputText] = React.useState<string | undefined>("");
  const [userCurrentMessage, setUserCurrentMessage] = React.useState<string | undefined>("");
  const context = React.useContext(ServiceContext);
  const [isCopilotVisible, setIsCopilotVisible] = React.useState(true);

  function renderChatMessage(messageData: MessageData, index: number) {
    switch (messageData.type) {
      case "copilot":
        return (
          <CopilotMessageWrapper
            key={`CopilotMessage-${index}`}
            {...messageData}
            userCurrentMessage={userCurrentMessage}
            conversationId={ConversationId}
          />
          
        );
      case "user":
        return (
          <UserMessage
            key={`UserMessage-${index}`}
            timestamp={messageData.timestamp}
            actionBar={
              <>
                <Button icon={<EditRegular />} appearance="transparent" />
                <Button icon={<BookmarkRegular />} appearance="transparent" />
                <Button icon={<ShareRegular />} appearance="transparent" />
              </>
            }
          >
            {messageData.message}
          </UserMessage>
        );
      case "system":
        return (
          <SystemMessageWrapper key={`SystemMessage-${index}`} {...messageData} />
        );
    }
  }

  const handleCloseCopilot = () => {
    setIsCopilotVisible(false);
    props.closeCopilot();
    logSessionTime();
  }

  const handleRefreshCopilot = () => {
    const apiUrl = "/api/UserWorkSpace/GetDocumentResponse";
        var postData = {
          initiateQuery: "",
          userQuery: "",
          ConversationId: ConversationId,
          userEmail: props.userEmail,
          isDirectSearch: false,
          isRefreshed: true,
          isStatic: false
        };
    postConsumptionAPI(apiUrl, postData, context.authClient);
    props.refreshCopilot();
    logSessionTime();
  }

  const onUpdate = (message: MessageStreamingData) => {
    setMessageHistory((messageHistory) =>
      updateMessageContent(
        messageHistory,
        message.responseId,
        message,
        message.loadingState
      )
    );
  };
  const llmClient = React.useRef<LLMClient>(new LLMClient(onUpdate));

  const logSessionTime = () => {
    const sessionEndTime = new Date();
    const durationInSeconds = Math.round(
      (sessionEndTime.getTime() - sessionStartTime.current.getTime()) / 1000
    );
    const durationInMinutes = (durationInSeconds / 60).toFixed(2);
    
    //telemetry: Session Time
    reduxDispatch(SETChileComponentName("WWIC Copilot"));
    reduxDispatch(SETHasServiceRequestTypeLogData(false));
    reduxDispatch(
      SETLogEventData({
        elementName: "WWIC Copilot: Session Time",
        elementId: "",
        action: "Session Duration Log",
        // message: durationInMinutes + " minutes",
        sessionDuration: durationInMinutes + " minutes",
        ConversationId: ConversationId
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
  }

  window.addEventListener("beforeunload", function (event) {
    if (messageHistory.length > 1) {
      logSessionTime(); // Call your function
      event.preventDefault(); // Some browsers show a generic prompt to the user
    }
  });

  React.useEffect(() => {
    sessionStartTime.current = new Date();
    if (!IsConversationCreated) {
      reduxDispatch(SetIsConversationId(true));
      const coversationapiUrl = '/api/UserWorkSpace/GetConversationId';
      postConsumptionAPI(coversationapiUrl, { userEmail: props.userEmail }, context.authClient)
        .then((response) => {
          if (response.data.conversationId) {
            reduxDispatch(SetCurrentConversationId(response.data.conversationId));
          } else {
            console.error("Error fetching conversation ID:", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching conversation ID:", error);
          // Optionally handle error cases, e.g., show a message to the user
        });
    }
  }, []);  

  const handleSubmit = async (userMessage?: string) => {

    // if (isLoading) return;
    // setShowForm(false);

    const message = userMessage ?? inputText.trim();
    // if (!message) return;
    setUserCurrentMessage(message);
    setInputText("");
    controlRef.current?.setInputText("");
    // setIsLoading(true);
    // setSuggestions([]);

    //telemetry: User Query
    reduxDispatch(SETChileComponentName("WWIC Copilot"));
    reduxDispatch(SETHasServiceRequestTypeLogData(false));
    reduxDispatch(
      SETLogEventData({
        elementName: "WWIC Copilot: User Query",
        elementId: "",
        action: "User asked query",
        userQuery: message,
        ConversationId: ConversationId
      })
    );
    reduxDispatch(SETIsCustomeLog(true));

    setMessageHistory((messageHistory) => [
      ...messageHistory,
      {
        type: "user",
        id: `user-${messageHistory.length}`,
        message,
        timestamp: formatCurrentTime(),
      },
    ]);

    setIsfetchingAiResponse(true);

    let initiateQuery;
    let isDirectSearch = false;
    let isStatic = false;

    if (props.subIssueTypes.length === 0) {
        // initiateQuery = message;
        isDirectSearch = true;
    } else {
        var areAllStatic = props.subIssueTypes.every(item => item.copilotRequiement.toLowerCase() === 'static');
        // initiateQuery = areAllStatic ? message : props.subIssueTypes[0].subIssueType;
        isDirectSearch = areAllStatic;
        isStatic = areAllStatic;
    }
    try {
        const apiUrl = "/api/UserWorkSpace/GetDocumentResponse";
        var postData = {
          initiateQuery: message,
          userQuery: message,
          ConversationId: ConversationId,
          userEmail: props.userEmail,
          isDirectSearch: isDirectSearch,
          isRefreshed: false,
          isStatic: isStatic
        };
        const response = await postConsumptionAPI(apiUrl, postData, context.authClient);
        console.log("Static response", response);
        //static and intent is quota
        if (response.status >= 200 && response.status < 300) { 
          if (areAllStatic && response.data === true) {
            props.subIssueTypes.forEach(item => {
              setMessageHistory((messageHistory) => [
                ...messageHistory,
                {
                  type: "copilot",
                  id: `copilot-${messageHistory.length}`,
                  message: item.aiResponse,
                  timestamp: formatCurrentTime(),
                },
              ]);
            });
            setIsfetchingAiResponse(false);
          }
          else if (response.data !== false && response.data !== true) {
            setMessageHistory((messageHistory) => [
              ...messageHistory,
              {
                type: "copilot",
                id: `copilot-${messageHistory.length}`,
                message: response.data,
                timestamp: formatCurrentTime(),
              },
            ]);
            setIsfetchingAiResponse(false);
            if (response.data.includes("Unfortunately, the AI could not provide a response at this moment.")) {
              // telemetry: No AI response
              reduxDispatch(SETChileComponentName("WWIC Copilot"));
              reduxDispatch(SETHasServiceRequestTypeLogData(false));
              reduxDispatch(
                SETLogEventData({
                  elementName: "WWIC Copilot: AI Response",
                  elementId: "",
                  action: "No AI Response received",
                  // message: "User Query: " + message + " Response: " + response.data,
                  userQuery: message,
                  aiResponse: response.data,
                  ConversationId: ConversationId
                })
              );
              reduxDispatch(SETIsCustomeLog(true));
            }            
          }
          else {
            setMessageHistory((messageHistory) => [
              ...messageHistory,
              {
                type: "copilot",
                id: `copilot-${messageHistory.length}`,
                message: 'Thank you for your query. Unfortunately, the AI could not provide a response at this moment. Please ensure your input is clear and specific of context.',
                timestamp: formatCurrentTime(),
              },
            ]);
            setIsfetchingAiResponse(false);
            //telemetry: No AI response
            reduxDispatch(SETChileComponentName("WWIC Copilot"));
            reduxDispatch(SETHasServiceRequestTypeLogData(false));
            reduxDispatch(
              SETLogEventData({
                elementName: "WWIC Copilot: AI Response",
                elementId: "",
                action: "No AI Response received",
                userQuery: message,
                aiResponse: response.data,
                ConversationId: ConversationId
              })
            );
            reduxDispatch(SETIsCustomeLog(true));
          }
        }
        else {
          setIsfetchingAiResponse(false);
          setMessageHistory((messageHistory) => [
            ...messageHistory,
            {
              type: "copilot",
              id: `copilot-${messageHistory.length}`,
              message: 'No AI response. Please ask question again.',
              timestamp: formatCurrentTime(),
            }
          ]);

          //telemetry: No AI response
          reduxDispatch(SETChileComponentName("WWIC Copilot"));
          reduxDispatch(SETHasServiceRequestTypeLogData(false));
          reduxDispatch(
            SETLogEventData({
              elementName: "WWIC Copilot: AI Response",
              elementId: "",
              action: "No AI Response received",
              // message: "User Query: " + message + " Response: " + response.data,
              userQuery: message,
              aiResponse: response.data,
              ConversationId: ConversationId
            })
          );
          reduxDispatch(SETIsCustomeLog(true));
        }
    }
    catch (error) {
      console.error("Error fetching the AI response", error);
      setMessageHistory((messageHistory) => [
        ...messageHistory,
        {
          type: "copilot",
          id: `copilot-${messageHistory.length}`,
          message: 'No AI response. Please ask question again.',
          timestamp: formatCurrentTime(),
        },
      ]);

      //telemetry: Error in fetching AI response
      reduxDispatch(SETChileComponentName("WWIC Copilot"));
          reduxDispatch(SETHasServiceRequestTypeLogData(false));
          reduxDispatch(
            SETLogEventData({
              elementName: "WWIC Copilot: AI Response",
              elementId: "",
              action: "No AI Response received",
              // message: "User Query: " + message + " Error: " + error,
              userQuery: message,
              aiResponse: error,
              ConversationId: ConversationId
            })
          );
          reduxDispatch(SETIsCustomeLog(true));
    }
    return undefined;
  };

  const chatInputRef = React.useRef(null);

  React.useEffect(() => {
    if (chatInputRef.current) {
      chatInputRef.current.focus();
    }
  }, [inputText]);

  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    let isUserScrolling = false; // Flag to track user scroll
  
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return undefined;
  
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = chatContainer;
      const isNearBottom = scrollHeight - scrollTop <= clientHeight + 50; // 50px tolerance for bottom
      const isAtTop = scrollTop === 0;
  
      // If user is at the top, reset the flag to allow auto-scroll for new messages
      if (isAtTop) {
        isUserScrolling = false;
      } else {
        isUserScrolling = !isNearBottom; // Update scrolling flag based on user position
      }
    };
  
    chatContainer.addEventListener("scroll", handleScroll);
  
    const observer = new MutationObserver(() => {
      if (!isUserScrolling && chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    });
  
    observer.observe(chatContainer, { childList: true, subtree: true });
  
    return () => {
      observer.disconnect();
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [messageHistory, isfetchingAiResponse]);
  
  const styles = useStyles();
  return (
    !isCopilotVisible ? null : (
      <div className={styles.provider}>
        <div className={styles.logoContainer}>
          <div className={styles.dissmissContainer}>
            <Button icon={<ArrowClockwiseRegular />} onClick={handleRefreshCopilot} />
            <Divider className={styles.divider} vertical />
            <Button icon={<DismissRegular />} onClick={handleCloseCopilot} />
          </div>
          <div className={styles.logoText}>
            <h1> <img src={copilotlogo} alt="Copilot Logo" className={styles.logoImage} />Copilot</h1>
            <div className={styles.logoPara}>
              <p>Your AI assistant for issue resolution </p>
            </div>
          </div>
        </div>
        <CopilotChat ref={chatContainerRef} className={styles.chat}>
          {messageHistory.map(renderChatMessage)}
          {/* {!subIssueSelected && props.subIssueTypes && isShoeSubIssue && (
            <>
              {props.subIssueTypes?.length > 1 ? <>
                <span>Please select subissue type</span>
                <RadioGroup
                  onChange={handleSubIssueTypeChange}
                >
                  {props.subIssueTypes.map((subIssueType, index) => (
                    <Radio key={index} value={subIssueType} label={subIssueType} />
                  ))}
                </RadioGroup>
              </> : null}
            </>
          )} */}
          {isfetchingAiResponse && (
            <div style={{ width: "100%", margin: "20px 0" }}>
              <ProgressIndicator label={indicatorLabel} description="This may take a few seconds." />
            </div>
          )}
          {/* {showForm && renderForm()} */}
        </CopilotChat>

        <ChatInput
          ref={chatInputRef}
          className={styles.chatInput}
          aria-label="Copilot Chat"
          maxLength={1000}
          charactersRemainingMessage={(value) => `${value} characters remaining`}
          placeholderValue="Ask questions"
          onChange={(e, d) => setInputText(d.value)}
          onSubmit={() => handleSubmit()}
          disableSend={isfetchingAiResponse || inputText.trim().length === 0}
        >
          <ImperativeControlPlugin ref={controlRef} />
        </ChatInput>
      </div>
    ));
};

export const ChatDocument = injectIntl(ChatComponent);

