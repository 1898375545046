/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, Suspense, lazy } from "react";
import "./V2_Tiles.css";
import { InjectedIntlProps, injectIntl } from "react-intl";
import {
  DocumentCardTitle,
  DocumentCardDetails,
  Text,
  IconButton,
  Icon,
  IDocumentCardTitleStyleProps,
  IDocumentCardTitleStyles,
  Stack,
  Label,
} from "@fluentui/react";
import { ServiceContext } from "@msx/platform-services";
import {
  deleteConsumptionAPI,
  getConsumptionAPI,
  putConsumptionAPI,
} from "../../../../utils/httpUtils";
import { useMyContext } from "../../../../context/myContext";
import ShimmerLoader from ".././ShimmerLoader";
import {
  SETChileComponentName,
  SETHasServiceRequestTypeLogData,
  SETIsCustomeLog,
  SETLogEventData,
  SETServiceRequestTypeLogData,
  getIsUserDataUpdated,
  setIsBotError,
  setIsLoader,
  setPreferenceChanged,
  setRecommendedTiles,
  settitleLoaderName,
  SetIsWwicCopilot,
} from "../../../../../core/store";
import { useDispatch, useSelector } from "react-redux";
import {
  V2_SERVICE_NOT_IN_PREFERENCE_Americas,
  V2_SERVICE_NOT_IN_PREFERENCE_NonAmericas,
  WEBFORM_IRIS_UTTERANCE,
  SERVICE_NOT_IN_WORKSPACE,
  SERVICE_LOCKED,
  AI_Tile_Telematry,
  Service_Tile_Telematry,
  AI_Telematry_message,
  NON_AI_Telematry_message,
  ai_servie_detail_panel_cancel,
  non_ai_service_detail_panel_cancel,
  ai_service_detail_cancel_message,
  non_ai_service_detail_cancel_message,
  AI_EMPTY_LABEL,
  DisableIRIS_DWR,
} from "../../../../utils/constants";
import {
  checkVisibilityOfTile,
  getFormNames,
} from "../../../TileConfiguration/V2_APIHelperFile";
import ErrorComponent from "../../../CatalogPage/ErrorComponent";
import { lazyRetry } from "../../../../utils/utils";
import DynamicDropdownGeneric from "./V2_DynamicDropdown";
const WebFormPanel = lazy(() =>
  lazyRetry(() => import("../../../WebFormPanel/WebFormPanel"))
);

export interface TileComponentProp extends InjectedIntlProps {
  setActiveTabKey?: any;
  userId: any;
  setIrisUtterance: any;
  setTileName: any;
  setIrisAppName: any;
  setSubSegment: any;
  setSegment: any;
  isUserLoggedIn: any;
  addServiceId: any;
  setAddServiceId: any;
  setServiceDeleted: any;
  userDataChanged: any;
  currentUserData: any;
  setFormDataToIris?: any;
  setShowBot?: any;
  setChatBotClicked?: any;
  finalTileObject?: any;
  setProfileLoading?: any;
  isCanceledPreset?: any;
  showErrorModal?: any;
  setShowErrorModal?: any;
  reccomendedData?: any;
  recommendationLoaded?: any;
  setRecommendationLoaded?: any;
  userWelcomeUpdated?: any;
  isAiEnabled: boolean;
}

const InfoPanel = lazy(() => lazyRetry(() => import("./V2_InfoPanel")));
const PopUpModal = lazy(() => lazyRetry(() => import(".././PopUpPanel")));
/**
 * Represents a tile component.
 *
 * @component
 * @param {TileComponentProp} props - The component props.
 * @returns {React.ReactElement} The rendered tile component.
 */
const TileComponent: React.FC<TileComponentProp> = (props) => {
  let irisBot = false;
  const { data, updateContextData } = useMyContext();
  const reduxDispatch = useDispatch();
  const userId = props.userId;
  const [openPanelId, setOpenPanelId] = React.useState(null);
  const [dataServiceWorkspace, setDataServiceWorkspace] = React.useState([]);

  const [dialogTitle, setDialogTitle] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDropdownModalVisible, setIsDropdownModalVisible] = useState(false);
  const [showTileNotFoundModal, setShowTileNotFoundModal] = useState({
    isVisible: false,
    message: "",
  });
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [deleteModalId, setDeleteModalId] = useState(null);
  const [deleteId, setDeleteId] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const irisAppName = useState("")[0];
  const context = React.useContext(ServiceContext);
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRecommended, setIsLoadingRecommended] = useState(true);
  const [resultError, setResultError] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [webFormIrisContext, setWebFormIrisContext] = useState({
    name: "",
    iriS_Utterance: "",
    tileName: "",
    serviceWithFormKey: "",
  });
  const [recommendedTile, setRecommendedTile] = useState([]);
  const [ResultErrorRecommendation, setResultErrorRecommendation] =
    useState("");
  const [isWebFormPanelOpen, setIsWebFormPanelOpen] = useState(false);
  const [selectedTileId, setSelectedTileId] = useState(null);
  const [selectedServiceTile, setSelectedServiceTile] = useState({});
  const [isTileFoundInPreference, setIsTileFoundInPreference] = useState(false);
  const [tileAlreadyInWorkspace, setTileAlreadyInWorkspace] = useState(false);
  const [serviceDeleted, setServiceDeleted] = useState(false);
  const [webFormNames, setWebFormNames] = useState([]);
  const [isGetTileExecuted, setGetTileExecuted] = useState(false);
  const [isWorkspaceLoad, setWorkspaceLoad] = useState(false);
  reduxDispatch(SETChileComponentName("My Help Workspace"));
  const APP_VERSION = process.env.APP_VERSION || "v1.0.0";
  const WEBFORMNAMES_LOCAL_STORAGE_KEY = `v2_webFormNames_${APP_VERSION}`;
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [subSegmentOptions, setSubSegmentOptions] = useState([]);
  const [segmentFinal, setSegmentFinal] = useState("");
  const [subSegmentFinal, setSubSegmentFinal] = useState("");
  const [segmentDropdownVisible, setSegmentDropdownVisible] = useState(false);
  const [pendingSegmentState, setPendingState] = useState(null);
  const [irisUtterance, setIrisUtterance] = useState(""); // Default value as empty string
  const [appName, setAppName] = useState(""); // Default value as empty string
  const [tileName, setTileName] = useState("");

  useEffect(() => {
    if (!segmentDropdownVisible && pendingSegmentState) {
      openWebFormFinal(
        pendingSegmentState.name,
        pendingSegmentState.iriS_Utterance,
        pendingSegmentState.serviceWithFormKey,
        tileName
      );
      setPendingState(null);
    }
  }, [pendingSegmentState, segmentDropdownVisible]);

  useEffect(() => {
    const fetchSegments = async () => {
      getAllSegments();
    };
    fetchSegments();
  }, []);

  const isTileVisibleForUser = () => {
    const isNonAmerica = props.finalTileObject.isNonAmerica;
    const isAmerica =
      props.finalTileObject.isAmerica &&
      props.finalTileObject.loadForms === true;
    const isAmericaSMC =
      props.finalTileObject.isAmericaSMC &&
      props.finalTileObject.loadForms === true;
    const isUserIdValid = userId !== -1;
    const isCurrentUserDefined = props.currentUserData !== undefined;

    return (
      (isNonAmerica || isAmerica || isAmericaSMC) &&
      isUserIdValid &&
      isCurrentUserDefined &&
      props.finalTileObject.isLoaded === false
    );
  };

  //get the tile name from localstorage
  const getStoredResult = () => {
    return localStorage.getItem(
      `TileVisibility_${userId}_${props.currentUserData.userArea}_${props.finalTileObject.TileName}`
    );
  };

  const handleTileFoundInCatalog = () => {
    // Store the result in localStorage
    localStorage.setItem(
      `TileVisibility_${userId}_${props.currentUserData.userArea}_${props.finalTileObject.TileName}`,
      props.finalTileObject.TileName
    );
    setIsTileFoundInPreference(true);
  };

  const handleTileNotFoundInCatalog = (isTileSecure) => {
    const errorMessage = props?.finalTileObject?.isAmerica
      ? V2_SERVICE_NOT_IN_PREFERENCE_Americas
      : isTileSecure
      ? SERVICE_LOCKED
      : V2_SERVICE_NOT_IN_PREFERENCE_NonAmericas;
    props.finalTileObject["Error"] = errorMessage;
    props.setProfileLoading(false);
    props.setShowBot(true);
    props.finalTileObject.isLoaded = true;
    props.finalTileObject.loadForms = false;
    getTiles();
  };

  const checkTileSecurity = async (tilename) => {
    const services = sessionStorage.getItem("v2_services");
    if (services === null) {
      return false;
    }
    const tiles = JSON.parse(services);
    const tile = tiles.find((tile) => tile.tileName === tilename);
    if (!tile) {
      return false;
    }
    return tile.isSecuredByAzureADGroup;
  };

  const fetchTileVisibility = useCallback(async () => {
    if (isTileVisibleForUser()) {
      let tileFoundInCatalog = false;
      let isTileSecure = await checkTileSecurity(
        props.finalTileObject.TileName
      );
      const storedResult = getStoredResult();
      props.setProfileLoading(true);
      props.setShowBot(false);
      if (
        storedResult !== null &&
        storedResult === props.finalTileObject.TileName
      ) {
        setTileAlreadyInWorkspace(true);
        tileFoundInCatalog = true;
      } else {
        tileFoundInCatalog = await checkVisibilitywithConfig();
      }

      if (tileFoundInCatalog !== true || isTileSecure) {
        handleTileNotFoundInCatalog(isTileSecure);
      } else {
        // Store the result in localStorage
        handleTileFoundInCatalog();
      }
    }
  }, [
    props.finalTileObject.isNonAmerica,
    props.finalTileObject.loadForms,
    props.currentUserData,
  ]);

  const checkVisibilitywithConfig = async () => {
    const checkVisibility = sessionStorage.getItem("CheckVisibility");
    if (
      props.finalTileObject.isAmerica === true ||
      props.finalTileObject.isAmericaSMC === true
    ) {
      if (checkVisibility === "true") {
        return await checkVisibilityOfTile(
          userId,
          props.finalTileObject.TileName,
          context
        );
      } else {
        return true;
      }
    } else if (props.finalTileObject.isNonAmerica === true) {
      return await checkVisibilityOfTile(
        userId,
        props.finalTileObject.TileName,
        context
      );
    }
  };

  const isUserDataChanged = useSelector(getIsUserDataUpdated);
  
  useEffect(()=>{
    getTiles();
  },[props.userWelcomeUpdated])

  if (isUserDataChanged) {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith(`TileVisibility_${userId}`)) {
        localStorage.removeItem(key);
      }
    }
  }

  const checkUrlParams = () => {
    const paramurl = window.location.search.toLocaleLowerCase();
    const isparamurlAmerica =
      paramurl.includes("tilename") && paramurl.includes("area");
    const isAmericaSMC =
      paramurl.includes("tilename") &&
      paramurl.includes("area") &&
      paramurl.includes("segment");
    const isparamurlNonAmerica =
      paramurl.includes("tilename") &&
      paramurl.includes("irisutterance") &&
      !paramurl.includes("mode");

    return { isparamurlAmerica, isparamurlNonAmerica, isAmericaSMC };
  };

  const handleNonAmericaOrAmerica = async () => {
    if (!isGetTileExecuted) {
      getTiles();
      getRecommendedTiles();
      setGetTileExecuted(true);
    }
  };

  const handleLoadedOrNonAmerica = async () => {
    const { finalTileObject } = props;

    if (
      (finalTileObject.isLoaded === false ||
        finalTileObject.isNonAmerica === true) &&
      finalTileObject.Error === ""
    ) {
      await fetchTileVisibility();

      if (isTileFoundInPreference || finalTileObject.isNonAmerica) {
        await getTiles();
        await getRecommendedTiles();
      }
    } else {
      const activeTabKey = sessionStorage.getItem("activeTabKey");
      if (activeTabKey === "0" || finalTileObject.Error !== "") {
        getTiles();
        getRecommendedTiles();
      }
    }
  };

  const handleUserDataChange = () => {
    ///// here ai logic
    if (props.recommendationLoaded === true) {
      setIsLoadingRecommended(true);
      getRecommendedTiles();
      props.setRecommendationLoaded(false);
    }
    getTiles();
    setSelectedTileId(null);
    props.setShowErrorModal({
      isErrorModalVisible: false,
      errorMessage: "",
    });
  };

  useEffect(() => {
    ///// here ai logic
    if (props.recommendationLoaded === true) {
      getRecommendedTiles();
      props.setRecommendationLoaded(false);
    }
  }, [
    userId,
    props.reccomendedData,
    props.recommendationLoaded,
    isUserDataChanged,
  ]);

  const fetchData = async () => {
    const isparamurlAmerica = checkUrlParams().isparamurlAmerica;
    const isparamurlNonAmerica = checkUrlParams().isparamurlNonAmerica;
    const isAmericaSMC = checkUrlParams().isAmericaSMC;

    if (!isparamurlAmerica && !isparamurlNonAmerica && !isAmericaSMC) {
      if (
        !props.finalTileObject.isNonAmerica ||
        !props.finalTileObject.isAmerica ||
        !props.finalTileObject.isAmericaSMC
      ) {
        await handleNonAmericaOrAmerica();
      }
    } else {
      await handleLoadedOrNonAmerica();
    }

    if (isUserDataChanged || serviceDeleted || props.isCanceledPreset) {
      handleUserDataChange();
    }
  };

  // useEffect for fetching tiles
  useEffect(() => {
    if (props.userId > 0) {
      fetchData();
    }
  }, [
    props.currentUserData,
    props.userId,
    deleteId,
    props.finalTileObject.isNonAmerica,
    props.finalTileObject.loadForms,
    isTileFoundInPreference,
    props.isCanceledPreset,
    isWorkspaceLoad,
  ]);

  useEffect(() => {
    if (isTileFoundInPreference) {
      if (
        dataServiceWorkspace.length > 0 &&
        props.finalTileObject.isLoaded === false
      ) {
        startTileFlowBasedOnSrc(props.finalTileObject.TileName);
      } else {
        handleTileNotLoaded();
      }
    }
  }, [dataServiceWorkspace]);

  const handleTileNotLoaded = () => {
    if (props.finalTileObject.isLoaded === false) {
      if (
        props.finalTileObject.isAmerica === true ||
        props.finalTileObject.isAmericaSMC === true
      ) {
        PresetPreference();
      } else {
        props.setProfileLoading(false);
        props.setShowBot(true);
        props.finalTileObject.isLoaded = true;
        props.finalTileObject.loadForms = false;
      }
    }
  };

  useEffect(() => {
    if (context) {
      fetchWebFormNames();
      cleanUpOldVersions(); // Clean up old versions when the app loads
    }
  }, [context]);

  const startTileFlowBasedOnSrc = useCallback(
    async (tileNameInSearchQuery) => {
      if (isTileFoundInPreference && dataServiceWorkspace.length > 0) {
        const tileExist = dataServiceWorkspace.filter(
          (tile) =>
            tile?.tileName
              ?.toLowerCase()
              .includes(tileNameInSearchQuery.toLowerCase()) ||
            tile?.name
              ?.toLowerCase()
              .includes(tileNameInSearchQuery.toLowerCase())
        );

        if (tileExist.length > 0) {
          await checkIrisService(
            tileExist[0].name,
            tileExist[0].service_IsNonIRISService,
            tileExist[0].iriS_Utterance,
            tileExist[0].serviceRequestFormLink,
            tileExist[0].irisAppName,
            tileExist[0].tileName,
            tileExist[0].serviceID,
            tileExist[0].openinBrowserTab,
            tileExist[0].isSegment
          );
          props.finalTileObject.isLoaded = true;
          props.finalTileObject.loadForms = false;
          setTimeout(() => {
            props.setProfileLoading(false);
          }, 700);
          props.setShowBot(true);
          if (
            props.finalTileObject.tileinfo === true &&
            props.finalTileObject.isNonAmerica === true
          ) {
            openPanel(tileExist[0].serviceID);
          }
          if (
            props.finalTileObject.sendServiceGroup == "true" ||
            props.finalTileObject.sendServiceGroup == true
          ) {
            setTimeout(() => {
              props.finalTileObject.sendServiceGroup = false;
            }, 10000);
          }
          return;
        } else {
          props.finalTileObject["Error"] = SERVICE_NOT_IN_WORKSPACE;
          props.setProfileLoading(false);
          props.setShowBot(true);
          props.finalTileObject.isLoaded = true;
          props.finalTileObject.loadForms = false;
        }
      }
    },
    [isTileFoundInPreference, dataServiceWorkspace]
  );

  const getTiles = async () => {
    try {
      if (context ? context.telemetryClient : "") {
        setIsLoading(true);
        if (
          data !== undefined &&
          data.length > 0 &&
          deleteId !== 204 &&
          props.addServiceId !== 201 &&
          !props.userDataChanged &&
          tileAlreadyInWorkspace
        ) {
          setDataServiceWorkspace(data);
          setResultError("success");
          return;
        }

        const [response1, response2] = await Promise.all([
          getConsumptionAPI(
            `/api/V2_UserWorkSpace/GetAllWorkspaces/${userId}`,
            context.authClient
          ),
          getConsumptionAPI(
            `/api/V2_UserWorkSpace/GetDefaultTileByUserId/${userId}`,
            context.authClient
          ),
        ]);
        let result1 = checkResponse(response1);
        let result2 = checkResponse(response2);
        if (result1 === "success" && result2 === "success") {
          setResultError("success");
          let jsonDataWorkspace = [];
          const data1 = await response1?.data;
          const data2 = await response2?.data;
          jsonDataWorkspace = [...data1, ...data2];
          setDataServiceWorkspace(jsonDataWorkspace);
          updateContextData(jsonDataWorkspace);
          setDeleteId(0);
          props.setAddServiceId(0);
        } else if (result2 === "error") {
          setResultError("error");
          setErrorData(response2?.data);
          setDataServiceWorkspace([]);
        } else {
          setResultError("error");
          setErrorData(response1?.data);
          setDataServiceWorkspace([]);
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching data.");
    } finally {
      if (Number.isInteger(userId)) {
        setIsLoading(false);
      }
    }
  };
  const getRecommendedTiles = async () => {
    try {
      if (
        context &&
        props.currentUserData?.isAiUser &&
        props?.currentUserData?.isAiServicesEnabled
          ? context.telemetryClient
          : ""
      ) {
        setIsLoadingRecommended(true);
        const responseRecommended = await getConsumptionAPI(
          `/api/V2_UserWorkSpace/GetAllRecommendedWorkspaces/${userId}`,
          context.authClient
        );
        let result1 = checkResponse(responseRecommended);
        if (result1 === "success") {
          setResultErrorRecommendation("success");
          setRecommendedTile(responseRecommended?.data);
          let data = responseRecommended?.data;
          reduxDispatch(setRecommendedTiles(data));
          setIsLoadingRecommended(false);
        } else {
          setErrorData(responseRecommended?.data);
          setResultErrorRecommendation("error");
        }
      } else {
        reduxDispatch(setRecommendedTiles([]));
      }
    } catch (error) {
      console.error("An error occurred while fetching data.");
    } finally {
      if (Number.isInteger(userId)) {
        setIsLoadingRecommended(false);
      }
    }
  };

  const getAllSegments = async () => {
    try {
      if (context && context.telemetryClient) {
        const response = await getConsumptionAPI(
          `/api/Segment/GetAll`,
          context.authClient
        );
        let result1 = checkResponse(response);
        if (result1 === "success") {
          // setSegmentOptions(response?.data)
          setSegmentOptions(
            response.data.map((item) => ({
              key: item.id,
              text: item.name,
            }))
          );
        } else {
          setErrorData(response?.data);
        }
      } else {
      }
    } catch (error) {
      console.error("An error occurred while fetching data.");
    } finally {
    }
  };

  const getAllSubSegmentFromSegment = async (key) => {
    try {
      if (context?.telemetryClient) {
        let response = await getConsumptionAPI(
          `/api/SubSegment/GetAllSubSegmentBySegmentId/${key}`,
          context.authClient
        );
        if (response.data) {
          let result = checkResponse(response);
          if (result === "success") {
            setSubSegmentOptions(
              response.data.map((item) => ({
                key: item.id,
                text: item.name,
              }))
            );
          }
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching data.");
    }
  };

  const passSegmentDataToIris = (segment, subSegment) => {
    props.setShowBot(false);
    setSegmentFinal(segment);
    setSubSegmentFinal(subSegment);
      sessionStorage.setItem("Segment", segment);
      sessionStorage.setItem("SubSegment", subSegment);
    handleChatBotIconClick(
      irisUtterance,
      appName,
      tileName,
      segment,
      subSegment
    );
  };

  const PresetPreference = async () => {
    const area = sessionStorage.getItem("AreaName");
    const role = sessionStorage.getItem("RoleName");
    const segment = sessionStorage.getItem("SegmentName");
    const subSegment = sessionStorage.getItem("SubSegmentName");

    try {
      const apiUrl = "/api/V2_User/PresetPreference";
      const postData = {
        //UPN: currentUser.email,
        //Oid: currentUser.userObject.localAccountId,
        UserArea: area,
        UserRole: role,
        Segment: segment,
        SubSegment: subSegment,
        DataverseRowID: "3f5a37dc-8d95-4232-85a7-990dab5888a9",
        IsActive: 1,
        IsWelcomeMessage: props.currentUserData.isWelcomeMessage,
      };

      const response = await putConsumptionAPI(
        apiUrl,
        postData,
        context.authClient
      );

      if (response.status >= 200 && response.status < 300) {
        props.finalTileObject.loadForms = true;
        setWorkspaceLoad(true);
      }
    } catch (error) {
      console.error("An error occurred while fetching the API.", error);
    }
  };

  const checkResponse = (response) => {
    switch (response.status) {
      case 200:
      case 201:
      case 204:
        return "success";
      case 401:
      case 500:
      default:
        return "error";
    }
  };

  const openPanel = (id, isRecommended = false) => {
    // Start Event Logging //
    let filteredService;
    !isRecommended
      ? (filteredService = dataServiceWorkspace.filter(
          (srv) => srv.serviceID === id
        ))
      : (filteredService = recommendedTile.filter(
          (srv) => srv.serviceID === id
        ));

    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(
      SETServiceRequestTypeLogData({
        ServiceName: filteredService[0].name,
        RequestType: "",
        TileName: filteredService[0].tileName,
        IsNonIrisService: filteredService[0].service_IsNonIRISService
          ? "true"
          : "false",
        IrisUtterance: filteredService[0].iriS_Utterance,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(
      SETLogEventData({
        elementName: isRecommended ? AI_Tile_Telematry : Service_Tile_Telematry,
        elementId: "",
        action: "click",
        message: isRecommended
          ? AI_Telematry_message
          : NON_AI_Telematry_message,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
    setOpenPanelId(id);
  };

  const openPanelOnKeyPress = (e, id) => {
    if (e.key === "Enter") {
      openPanel(id);
    }
  };

  const dismissPanel = (isrecommended = false) => {
    setOpenPanelId(null);
    // Start Event Logging //
    reduxDispatch(SETHasServiceRequestTypeLogData(true));

    reduxDispatch(
      SETLogEventData({
        elementName: isrecommended
          ? ai_servie_detail_panel_cancel
          : non_ai_service_detail_panel_cancel,
        elementId: "Cancel",
        action: "Click",
        message: isrecommended
          ? ai_service_detail_cancel_message
          : non_ai_service_detail_cancel_message,
        profileSetting: `v2`,
      })
    );

    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
  };

  const dismissPanelOnKeyPress = (e, id) => {
    if (e.key === "Enter") {
      setOpenPanelId(null);
      // Start Event Logging //
      reduxDispatch(SETHasServiceRequestTypeLogData(true));
      reduxDispatch(
        SETLogEventData({
          elementName: "Service Details Panel Cancel",
          elementId: "Cancel",
          action: "Key Press",
          message: "User Key Press on Service Details Panel Cancel",
          profileSetting: `v2`,
        })
      );

      reduxDispatch(SETIsCustomeLog(true));
      // End Event Logging //
    }
  };

  const GotoCatalogPage = async () => {
    // Start Event Logging //
    reduxDispatch(
      SETLogEventData({
        elementName: "Add more Help to My Help Workspace",
        elementId: "",
        action: "click",
        message: "User click on Add more Help to My Help Workspace",
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
    props.setActiveTabKey("1");
  };

  const [iframeUrl, setIframeUrl] = useState("");

  const openWindowFrame = (url, openinBrowserTab) => {
    if (url ? url.includes("forms") : null) {
      setIframeUrl(url);
    }
    openIframe(url, openinBrowserTab);
  };

  const checkIrisService = async (
    name,
    isNonIrisService,
    iriS_Utterance,
    serviceRequestFormLink,
    irisAppName,
    tileName,
    serviceID,
    openinBrowserTab,
    isrecommended = false,
    isSegment = false
  ) => {
    setSelectedTileId(serviceID);
    // Start Event Logging //
    reduxDispatch(setPreferenceChanged(false));
    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(
      SETServiceRequestTypeLogData({
        ServiceName: name,
        RequestType: "",
        TileName: tileName,
        IsNonIrisService: isNonIrisService ? "true" : "false",
        IrisUtterance: iriS_Utterance,
        profileSetting: `v2`,
      })
    );
    const elementname = isrecommended
      ? AI_Tile_Telematry
      : Service_Tile_Telematry;
    const message = isrecommended
      ? AI_Telematry_message
      : NON_AI_Telematry_message;
    reduxDispatch(
      SETLogEventData({
        elementName: elementname + "Start",
        elementId: "",
        action: "click",
        message: message + "start",
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));

    irisBot = false;
    reduxDispatch(settitleLoaderName(name));
    reduxDispatch(SETIsCustomeLog(true));
    reduxDispatch(setIsLoader(true));
    reduxDispatch(setIsBotError(false));
    // End Event Logging //
    let parsedData = null;
    if (
      serviceRequestFormLink?.startsWith("[") &&
      serviceRequestFormLink?.endsWith("]")
    ) {
      parsedData = JSON.parse(serviceRequestFormLink);
    }
    ifandelsecondition(
      name,
      isNonIrisService,
      iriS_Utterance,
      tileName,
      serviceRequestFormLink,
      parsedData,
      openinBrowserTab,
      isSegment
    );
  };

  const hideSegmentDropdown = () => {
    setSegmentDropdownVisible(false);
  };
  const ifandelsecondition = (
    name: any,
    isNonIrisService: any,
    iriS_Utterance: any,
    tileName: any,
    serviceRequestFormLink: any,
    parsedData: any,
    openinBrowserTab: any,
    isSegment: any
  ) => {
    if (!isNonIrisService) {
      IsNonIrisService(name, iriS_Utterance, tileName, isSegment);
    } else if (serviceRequestFormLink === null) {
      setIsErrorModalVisible(true);
    } else if (isNonIrisService && parsedData === null) {
      openWindowFrame(serviceRequestFormLink, openinBrowserTab);
    } 
    // else if (isSegment === true) {
    //   setDialogTitle(name);
    //   setIrisUtterance(iriS_Utterance);
    //   setAppName(irisAppName);
    //   setTileName(tileName);
    //   setSegmentDropdownVisible(true);
    // }
     else if (
      isNonIrisService &&
      parsedData !== null &&
      parsedData.length >= 1
    ) {
      elseSecondCondition(parsedData, name);
    } else {
      console.log("Error");
    }
  };

  const fetchWebFormNames = async () => {
    let webFormNames: any[] = localStorage.getItem(
      WEBFORMNAMES_LOCAL_STORAGE_KEY
    )
      ? JSON.parse(localStorage.getItem(WEBFORMNAMES_LOCAL_STORAGE_KEY)!)
      : [];

    if (webFormNames.length === 0) {
      // Fetch new data since it's not available in localStorage
      webFormNames = await getFormNames(context);
      // Store the result in localStorage with the versioned key
      localStorage.setItem(
        WEBFORMNAMES_LOCAL_STORAGE_KEY,
        JSON.stringify(webFormNames)
      );
    }
    setWebFormNames(webFormNames);
  };

  const cleanUpOldVersions = () => {
    const allKeys = Object.keys(localStorage);
    const currentKeyPrefix = "v2_webFormNames";

    allKeys.forEach((key) => {
      if (
        key.startsWith(currentKeyPrefix) &&
        key !== WEBFORMNAMES_LOCAL_STORAGE_KEY
      ) {
        localStorage.removeItem(key); // Remove old versioned data
      }
    });
  };

  const IsNonIrisService = (
    name: string,
    iriS_Utterance: string,
    tileName: string,
    isSegment: boolean
  ) => {
    let isTileConsistsWebform = false;
    // Find the service based on iriS_Utterance and isAmerica flag
    const getServiceFormKey = (isAmerica: boolean) => {
      const service = webFormNames?.find(
        (item) => item.tileName === tileName && item.isAmerica === isAmerica
      );
      return service?.formKey;
    };

    // Determine formKey based on iriS_Utterance
    const serviceWithFormKey =
      iriS_Utterance === WEBFORM_IRIS_UTTERANCE
        ? getServiceFormKey(true)
        : getServiceFormKey(false);

    // Check if the tileName is in the list of web form names
    if (serviceWithFormKey !== undefined) {
      isTileConsistsWebform = webFormNames?.some(
        (item) => item.tileName === tileName
      );
    }

    // Handle the web form logic
    if (isSegment) {
      handleFormAndSegment(
        isTileConsistsWebform,
        name,
        iriS_Utterance,
        serviceWithFormKey,
        tileName
      );
    } else if (isTileConsistsWebform) {
      openWebFormFinal(name, iriS_Utterance, serviceWithFormKey,tileName);
    } else if (tileName === "WWIC Seller" && !isTileConsistsWebform && props.isAiEnabled) {
      props.setShowBot(false);
      checksIris();
      handleChatBotIconClick(iriS_Utterance, irisAppName, tileName);
      reduxDispatch(SetIsWwicCopilot(true));
    }
    else {
      props.setShowBot(false);
      checksIris();
      handleChatBotIconClick(iriS_Utterance, irisAppName, tileName);
    }
  };

  const handleFormAndSegment = (
    isTileConsistsWebform,
    name,
    iriS_Utterance,
    serviceWithFormKey,
    tileName
  ) => {
    setDialogTitle(name);
    setIrisUtterance(iriS_Utterance);
    setAppName(irisAppName);
    setTileName(tileName);
    setSegmentDropdownVisible(true);
    if (isTileConsistsWebform) {
      setPendingState({ name, iriS_Utterance, serviceWithFormKey });
    }
  };

  const openWebFormFinal = (name, iriS_Utterance, serviceWithFormKey,tileName) => {
    if (!segmentDropdownVisible) {
      openWebForm(name, iriS_Utterance, tileName, serviceWithFormKey);
      props.setShowBot(false);
      handleChatBotIconClick("", irisAppName, tileName);
      sessionStorage.setItem("WebFormOpen", "true");
      disableDWRIris(tileName);
    }
  };
  const disableDWRIris=(tileName:any)=>{
    if(tileName === DisableIRIS_DWR)
      { 
        setTimeout(() => {
            const irisHeaderElement = document.querySelector('._3bARu');
            // Check if the element exists and get its parent
            if (irisHeaderElement) {
                const parentElement = irisHeaderElement.parentElement;
                parentElement.style.display = 'none';
            }
        }, 3000); // 2000ms = 2 seconds
      }
  }
  const checksIris = () => {
    if (!irisBot) {
      const irisMenu = document.querySelector(".ms-layer");
      if (irisMenu?.innerHTML !== "") {
        setTimeout(() => {
          reduxDispatch(setIsLoader(false));
          irisBot = true;
        }, 10000);
      } else {
        reduxDispatch(setIsLoader(true));
        setTimeout(checksIris, 30);
      }
    }
  };
  const elseSecondCondition = (parsedData: any, name: any) => {
    let array2 = [];
    elseCondition(parsedData, array2, name);
  };

  const elseCondition = (parsedData: any, array2: any, name: any) => {
    if (parsedData) {
      elseConditionLoop(array2, parsedData);
      if (array2.length === 1) {
        openWindowFrame(
          array2[0].ServiceRequestFormLink,
          array2[0].OpeninBrowserTab
        );
      } else if (array2.length > 1) {
        setDialogTitle(name);
        setSelectedOptions(array2);
        setIsDropdownModalVisible(true);
      }
    }
  };
  const elseConditionLoop = (array2: any, parsedData: any) => {
    for (let i = 0; i < parsedData.length; i++) {
      let e = parsedData[i];
      if (e.RequestTypeName === "N/A" && e.ServiceRequestFormLink !== null) {
        openWindowFrame(e.ServiceRequestFormLink, e.OpeninBrowserTab);
        array2.length = 0;
        break;
      } else if (
        e.RequestTypeName !== "N/A" &&
        e.RequestTypeName !== null &&
        e.ServiceRequestFormLink === null
      ) {
        continue;
      } else if (
        (e.RequestTypeName === null || e.RequestTypeName === "N/A") &&
        e.ServiceRequestFormLink === null
      ) {
        continue;
      } else {
        array2.push({
          key: e.RequestTypeName + i,
          text: e.RequestTypeName,
          value: e.ServiceRequestFormLink,
          openinBrowserTab: e.OpeninBrowserTab,
        });
      }
    }
  };

  const openWebForm = async (
    name,
    iriS_Utterance,
    tileName,
    serviceWithFormKey
  ) => {
    setWebFormIrisContext({
      name,
      iriS_Utterance,
      tileName,
      serviceWithFormKey,
    });
    setIsWebFormPanelOpen(true);
  };

  const closeWebFormPanel = () => {
    setIsWebFormPanelOpen(false);
  };

  const handleChatBotIconClick = async (
    iriS_Utterance,
    irisAppName,
    tileName,
    segment = "N/A",
    subsegment = "N/A"
  ) => {
    const isForm = sessionStorage.getItem("WebFormOpen");
    var sessionSubSegment = null;
    var sessionSegment = null;
    if (isForm === "true") {
      sessionSegment = sessionStorage.getItem("Segment");
      sessionSubSegment = sessionStorage.getItem("SubSegment");
    }
    props.setChatBotClicked(true);
    props.setIrisUtterance(iriS_Utterance);
    props.setIrisAppName(irisAppName);
    props.setTileName(tileName);
    props.setSegment(sessionSegment ? sessionSegment : segment);
    props.setSubSegment(sessionSubSegment ? sessionSubSegment : subsegment);
    reduxDispatch(setIsBotError(false));
    if (
      isForm === "true" &&
      sessionSegment !== null &&
      sessionSubSegment !== null
    ) {
      sessionStorage.setItem("WebFormOpen","false");
      sessionStorage.removeItem("Segment");
      sessionStorage.removeItem("SubSegment");
    }
  };

  const deleteService = async (id) => {
    try {
      hideDeleteModal();
      setIsLoading(true);
      if (context ? context.telemetryClient : "") {
        //Removed useless assignment to variable "Upn"
        const responseServiceWorkspaceDelete = await deleteConsumptionAPI(
          `/api/V2_UserWorkSpace/Delete/${id}`,
          context.authClient
        );
        setIsLoading(false);
        setServiceDeleted(true);
        setDeleteId(responseServiceWorkspaceDelete.status);
        props.setServiceDeleted(true);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("An error occurred while fetching api.", error);
    }
  };

  const showDeleteModal = (id) => {
    setDeleteModalId(id);
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalId(null);
    setIsDeleteModalVisible(false);
  };

  const hideErrorModal = () => {
    setIsErrorModalVisible(false);
    setShowTileNotFoundModal({ isVisible: false, message: "" });
  };

  const handleConfirmDelete = async (id) => {
    await deleteService(id);

    // Find the service that was deleted
    const serviceDeleted = dataServiceWorkspace.find((tile) => tile.id === id);
    // Remove the visibility data from local storage if the service was found
    if (serviceDeleted !== undefined) {
      localStorage.removeItem(
        `TileVisibility_${userId}_${props.currentUserData.userArea}_${serviceDeleted.tileName}`
      );
    }
    hideDeleteModal();

    // Start Event Logging //

    let tile: any = selectedServiceTile as any;

    // Set the flag to indicate that service request type log data is available
    reduxDispatch(SETHasServiceRequestTypeLogData(true));

    // Set the log event data for the delete confirmation button click
    reduxDispatch(
      SETLogEventData({
        elementName: "Service Tile Delete Confirmation Yes",
        elementId: "Yes",
        action: "click",
        message: "User click on Service Tile Delete Confirmation Yes",
        profileSetting: `v2`,
      })
    );

    // Set the service request type log data
    reduxDispatch(
      SETServiceRequestTypeLogData({
        ServiceName: tile.name,
        RequestType: "",
        TileName: tile.tileName,
        IsNonIrisService: tile.service_IsNonIRISService ? "true" : "false",
        IrisUtterance: tile.iriS_Utterance,
        profileSetting: `v2`,
      })
    );
    reduxDispatch(SETIsCustomeLog(true));
    // End Event Logging //
  };
  const handleCancelDelete = () => {
    hideDeleteModal();
  };

  const handleCancelError = () => {
    hideErrorModal();
  };

  const handleCancelDropdown = () => {
    setIsDropdownModalVisible(false);
  };

  const truncateText = (text, limit) => {
    if (text != null) {
      if (text.length <= limit) {
        return text;
      }
    } else {
      text = "";
    }
    return `${text.substring(0, limit)} ...`;
  };

  const openModal = (newValue, openinBrowserTab = false) => {
    setValue(newValue);
    openIframe(newValue, openinBrowserTab);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank").focus();
  };

  const openIframe = (iframeSrc, openinBrowserTab) => {
    try {
      if (!openinBrowserTab) {
        const iframe = document.createElement("iframe");
        iframe.src = iframeSrc;
        window.open(
          iframe.src,
          "thirdPartyPopup",
          "width=" +
            window.outerWidth * 0.9 +
            ", height=" +
            window.outerHeight * 0.7 +
            ", top=200, left=42, frameborder=0, allowfullscreen, toolbar=no ,location=0, status=no, titlebar=no, menubar=no"
        );
      } else {
        openInNewTab(iframeSrc);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openFAQs = (faqLink) => {
    window.open(faqLink, "_blank");
  };

  const GetPanelWidth = (isLarge, htmlContent) => {
    let panelWidth = "25vw";

    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Access the table and count the number of columns in the first row
    let table = tempDiv.querySelector(".Table");
    if (table) {
      let firstRow = table.querySelector("tr");
      let numberOfColumns = firstRow.children.length;

      if (numberOfColumns > 1) {
        panelWidth = "50vw";
      }

      AdjustColumnWidth(numberOfColumns);
    }
    return panelWidth;
  };
  const renderTile = (tile, isRecommended) => (
    <div
      className={`multipleTiles ${
        selectedTileId === tile.serviceID ? "highlighted" : ""
      }`}
      key={tile.id}>
      <div
        className="tileContent"
        style={{ cursor: "pointer" }}
        tabIndex={0}
        role="button"
        onClick={() => openPanel(tile.serviceID, isRecommended)}
        onKeyDown={(event) => openPanelOnKeyPress(event, tile.serviceID)}>
        <h4>
          <div className="tileTitle">
            <DocumentCardTitle
              title={tile.name}
              styles={(props: IDocumentCardTitleStyleProps) => customStyles}
              aria-label={tile.name}
            />
          </div>
        </h4>
        <div className="info-icon">
          <Icon iconName="Info" />
          <div className="tooltip">
            <div
              className="tooltip-content"
              aria-label={truncateText(tile.aboutService, 255)}>
              {truncateText(tile.aboutService, 255)}
            </div>
            <div className="arrow-up"></div>
          </div>
        </div>
      </div>
      <div className="separator"></div>
      <div
        style={{
          height: "21px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="NewDynamicSection">
        <IconButton
          iconProps={{ iconName: "Delete" }}
          aria-label="Delete"
          styles={{
            root: {
              display: "flex",
              alignItems: "center",
              color: "black",
            },
          }}
          style={{
            pointerEvents:
              tile.defaultloadtile || isRecommended ? "none" : "auto",
            visibility:
              tile.defaultloadtile || isRecommended ? "hidden" : "visible",
          }}
          onClick={async () => {
            setSelectedServiceTile(tile);
            // Start Event Logging //

            reduxDispatch(SETHasServiceRequestTypeLogData(true));
            reduxDispatch(
              SETLogEventData({
                elementName: isRecommended
                  ? AI_Tile_Telematry + "Delete"
                  : Service_Tile_Telematry + "Delete",
                elementId: "Delete",
                action: "click",
                message: isRecommended
                  ? AI_Tile_Telematry + "Delete"
                  : NON_AI_Telematry_message + "Delete",
                profileSetting: `v2`,
              })
            );
            reduxDispatch(
              SETServiceRequestTypeLogData({
                ServiceName: tile.name,
                RequestType: "",
                TileName: tile.tileName,
                IsNonIrisService: tile.service_IsNonIRISService
                  ? "true"
                  : "false",
                IrisUtterance: tile.iriS_Utterance,
                profileSetting: `v2`,
              })
            );
            reduxDispatch(SETIsCustomeLog(true));
            // End Event Logging //
            showDeleteModal(tile.id);
          }}
        />

        {deleteModalId == tile.id && (
          <Suspense fallback={<></>}>
            <PopUpModal
              isVisible={isDeleteModalVisible}
              onConfirm={handleConfirmDelete}
              onCancel={handleCancelDelete}
              onHide={hideDeleteModal}
              itemId={deleteModalId}
              dialogTitle={"Delete Confirmation"}
              dialogSubText={
                "Are you sure you want to remove this service? You can always find it again in the Catalog."
              }
              buttonsCount={2}
              buttonTextOne={"Yes"}
              buttonTextTwo={"No"}
            />
          </Suspense>
        )}
        {tile.faqLink != null && (
          <button
            style={{
              marginLeft: "75px",
            }}
            className="tileButton"
            onClick={async () => {
              // Start Event Logging //
              reduxDispatch(SETHasServiceRequestTypeLogData(true)); // NOSONAR
              reduxDispatch(
                SETServiceRequestTypeLogData(
                  // NOSONAR
                  {
                    ServiceName: tile.name,
                    RequestType: "",
                    TileName: tile.tileName,
                    IsNonIrisService: tile.service_IsNonIRISService
                      ? "true"
                      : "false",
                    IrisUtterance: tile.iriS_Utterance,
                    profileSetting: `v2`,
                  }
                )
              );
              reduxDispatch(
                SETLogEventData(
                  // NOSONAR
                  {
                    elementName: isRecommended
                      ? AI_Tile_Telematry + "FAQ's"
                      : Service_Tile_Telematry + "FAQs",
                    elementId: "FAQs",
                    action: "click",
                    message: isRecommended
                      ? AI_Telematry_message + "FAQ's"
                      : NON_AI_Telematry_message + "FAQ's",
                    profileSetting: `v2`,
                  }
                )
              );
              reduxDispatch(SETIsCustomeLog(true));
              // End Event Logging //
              openFAQs(tile.faqLink);
            }}>
            FAQs
          </button>
        )}
        <button
          className="tileButtonDynamic"
          ria-label="Start"
          onClick={() => {
            // Call the checkIrisService function
            checkIrisService(
              tile.name,
              tile.service_IsNonIRISService,
              tile.iriS_Utterance,
              tile.serviceRequestFormLink,
              tile.irisAppName,
              tile.tileName,
              tile.serviceID,
              tile.openinBrowserTab,
              isRecommended,
              tile.isSegment
            );
          }}>
          Start
        </button>
        {selectedTileId == tile.serviceID && (
          <Suspense fallback={<></>}>
            <PopUpModal
              isVisible={isErrorModalVisible}
              onCancel={handleCancelError}
              onHide={hideErrorModal}
              dialogTitle={"Error"}
              dialogSubText={"Oops something is missing!"}
              buttonsCount={1}
              buttonTextTwo={"OK"}
            />
          </Suspense>
        )}

        {/* {selectedTileId == tile.serviceID && (
          <Suspense fallback={<></>}>
            <PopUpModal
              isVisible={isDropdownModalVisible}
              onCancel={handleCancelDropdown}
              onHide={handleCancelDelete}
              dialogTitle={dialogTitle}
              dialogSubText={"Select a request type"}
              buttonsCount={0}
              buttonTextOne={"Start"}
              buttonTextTwo={"Cancel"}
              options={selectedOptions}
              modalValue={value}
              openModal={openModal}
            />
          </Suspense>
        )} */}

        {selectedTileId == tile.serviceID && (
          <DynamicDropdownGeneric
            numberOfDropdown={1}
            firstDropDownOptions={selectedOptions}
            dialogTitle={dialogTitle}
            dialogSubText={"Select a Request Type"}
            isVisible={isDropdownModalVisible}
            modalValue={value}
            openModal={openModal}
            onHide={handleCancelDropdown}
            onCancel={handleCancelDropdown}
          />
        )}

        {selectedTileId == tile.serviceID && (
          <DynamicDropdownGeneric
            numberOfDropdown={2}
            firstDropDownOptions={segmentOptions}
            dialogTitle={dialogTitle}
            isVisible={segmentDropdownVisible}
            onHide={hideSegmentDropdown}
            onCancel={hideSegmentDropdown}
            secondDropDownOptions={subSegmentOptions}
            passSegmentDataToIris={passSegmentDataToIris}
            getAllSubSegmentFromSegment={getAllSubSegmentFromSegment}
          />
        )}

        {selectedTileId == tile.serviceID && (
          <Suspense fallback={<></>}>
            <PopUpModal
              isVisible={showTileNotFoundModal.isVisible}
              onCancel={handleCancelError}
              onHide={hideErrorModal}
              dialogTitle={"Error"}
              dialogSubText={showTileNotFoundModal.message}
              buttonsCount={1}
              buttonTextTwo={"OK"}
            />
          </Suspense>
        )}
      </div>
      {openPanelId === tile.serviceID && <div className="infopanle-overlay" />}

      {openPanelId === tile.serviceID && (
        <Suspense fallback={<></>}>
          <InfoPanel
            isOpen={openPanelId === tile.serviceID}
            dismissPanel={dismissPanel}
            title={tile.name}
            buttonText="Start"
            buttonSymbol=""
            checkIrisService={checkIrisService}
            isNonIRISService={tile.service_IsNonIRISService}
            iriS_Utterance={tile.iriS_Utterance}
            irisAppName={tile.irisAppName}
            serviceRequestFormLink={tile.serviceRequestFormLink}
            customWidth={GetPanelWidth(
              tile.service_IsLarge,
              tile.serviceCategories
            )}
            dismissPanelOnKeyPress={dismissPanelOnKeyPress}
            upn={tile.upn}
            displayName={tile.displayName}
            userId={props.userId}
            serviceId={tile.serviceID}
            tileName={tile.tileName}
            openinBrowserTab={tile.openinBrowserTab}
            isrecommended={isRecommended}
            isSegment={tile.isSegment}
          />
        </Suspense>
      )}
    </div>
  );

  const renderTiles = (isRecommended = false) =>
    !isRecommended
      ? dataServiceWorkspace.map((tile) => renderTile(tile, isRecommended))
      : recommendedTile.map((tile) => renderTile(tile, isRecommended));

  const renderError = () => (
    <div
      style={{
        justifyContent: "center",
        alignContent: "center",
        marginRight: 30,
        width: "50%",
      }}>
      <ErrorComponent errorData={errorData} />
    </div>
  );

  const loadExistingTiles = () => {
    let content;
    if (resultError === "success") {
      if (dataServiceWorkspace.length > 0) {
        content = renderTiles();
      } else {
        content = <></>; // or some other default content
      }
    } else {
      content = renderError();
    }

    return content;
  };

  const loadRecommendationTile = () => {
    let data;
    if (ResultErrorRecommendation === "success") {
      if (recommendedTile.length > 0) {
        data = renderTiles(true);
      } else {
        data = (
          <>
            <Label
              style={{
                backgroundColor: "white",
                padding: "1vh",
                marginTop: "2vh",
              }}>
              {AI_EMPTY_LABEL}
            </Label>
          </>
        );
      }
    } else if (ResultErrorRecommendation === "error") {
      data = renderError();
    } else {
      <></>;
    }

    return data;
  };

  const AdjustColumnWidth = (columnCount) => {
    setTimeout(() => {
      let tableItems = document.querySelectorAll(".panelContainer table td");

      let columnClass = "onecolumn";
      if (columnCount > 1) {
        columnClass = "twocolumn";
      }
      tableItems.forEach((tableItems) => {
        if (tableItems) tableItems.classList.add(columnClass);
      });
    }, 100);
  };

  const customStyles: IDocumentCardTitleStyles = {
    root: {
      fontFamily: "Segoe UI",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "0em",
      textAlign: "left",
      padding: "3px 5px",
      maxWidth: "35ch",
      overflow: "hidden",
      textOverflow: "ellipsis",
      height: "69px",
    },
  };
  return (
    <>
      {/* <div style={{marginTop:"15rem",textAlign:"center",fontSize:"X-Large"}}>
        New Tile Component
      </div> */}
      {isLoading && <ShimmerLoader count={2} />}
      {!isLoading && (
        <div className="tilecontainer">
          <label className="servicesLabel" htmlFor="myHelp">
            <h3
              style={{
                color: "black",
                fontFamily: "Segoe UI",
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "24px",
                marginBottom: "8px",
              }}>
              My Help
            </h3>
          </label>
          <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "2px" }}>
            {loadExistingTiles()}

            <div
              className="multipleTiles staticTile"
              tabIndex={0}
              onClick={GotoCatalogPage}
              style={{ cursor: "pointer" }}>
              <button
                aria-label="Add more Help to My Help Workspace"
                className="staticTileButton">
                <IconButton
                  aria-label="Add more Help to My Help Workspace"
                  iconProps={{ iconName: "Add" }}
                  style={{ color: "black", fontWeight: "bold" }}
                  tabIndex={-1}
                />
              </button>
              <DocumentCardDetails>
                <Text
                  style={{
                    fontFamily: "Segoe UI",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}>
                  Add more Help
                  <br />
                  to My Help Workspace
                </Text>
              </DocumentCardDetails>
            </div>
          </div>
          {isWebFormPanelOpen && (
            <Suspense fallback={<></>}>
              <WebFormPanel
                isPanelOpen={isWebFormPanelOpen}
                closeWebFormPanel={closeWebFormPanel}
                formDataToIris={props.setFormDataToIris}
                showBot={props.setShowBot}
                irisContext={webFormIrisContext}
                chatBotIconClick={handleChatBotIconClick}
              />
            </Suspense>
          )}
        </div>
      )}

      {/* {isLoadingRecommended &&
        props.currentUserData?.isAiUser &&
        props?.currentUserData?.isAiServicesEnabled && (
          <ShimmerLoader count={2} />
        )}
      {!isLoadingRecommended &&
        props.currentUserData?.isAiUser &&
        props?.currentUserData?.isAiServicesEnabled && (
          <div className="tilecontainer">
            <label className="servicesLabel" htmlFor="myHelp">
              <h3
                style={{
                  color: "black",
                  fontFamily: "Segoe UI",
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  marginBottom: "8px",
                }}>
                AI Recommended Services
              </h3>

              <text className="aiText">
                AI-generated Services may be inaccurate
              </text>
            </label>
            <div
              style={{ display: "flex", flexWrap: "wrap", marginLeft: "2px" }}>
              {loadRecommendationTile()}
            </div>
          </div>
        )} */}
    </>
  );
};
export const V2_Tile = injectIntl(TileComponent);
