import React, { lazy, Suspense, useState } from "react";
import './V2_headerTab.css'
import { Pivot, PivotItem } from "@fluentui/react";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { SETChileComponentName, SETHasServiceRequestTypeLogData, SETIsCustomeLog, SETLogEventData, setIsBotError, setIsLoader, settitleLoaderName } from "../../../../core/store";
import { useDispatch } from "react-redux";
import { lazyRetry } from "../../../utils/utils";
const ErrorPopUpMessage = lazy(() => lazyRetry(() => import(".././ErrorPopUpMessage")));

const AnnouncementBanner = lazy(() =>
  lazyRetry(() =>
    import('../../banner/AnnouncementBanner').then(module => ({ default: module.AnnouncementBanner }))
  )
);

const Message = lazy(() => lazyRetry(() => import("../../main/Messages/messages")));

export interface HeaderTabProp extends InjectedIntlProps {
  myHelpWorkspaceComponent: React.ReactNode;
  catalogComnponent: React.ReactNode;
  activeTabKey: string;
  setActiveTabKey?: any;
  currentUserData: any;
  showErrorModal:any;
  setShowErrorModal:any;
}

const HeaderTab: React.FC<HeaderTabProp> = (props) => {
  let {showErrorModal}=props;
  let irisBot = false;
  const reduxDispatch = useDispatch();

  const [isitemKey, setIsitemKey] = useState(false);
  
  const TAB_MYHELP_WORKSPACE = "My Help Workspace";
  const TAB_CATALOG = "Catalog";

  const getActivePage = () => {
    let pagename = "";
    switch (props.activeTabKey) {
      case "0":
        pagename = TAB_MYHELP_WORKSPACE
        break;
      case "1":
        pagename = TAB_CATALOG
        break;

      default:
        break;
    }
    return pagename
  }
  const setActiveTabKeyinHeaderTab = (item?: PivotItem) => {
    if (item.props.itemKey === "0") {

      irisBot = false;
      setIsitemKey(true)
      checkIris();
      reduxDispatch(setIsLoader(true));
    }
    else {
      setIsitemKey(false)
      reduxDispatch(setIsLoader(false));
    }
    reduxDispatch(settitleLoaderName(""));
    reduxDispatch(setIsBotError(false));
    //Start Event Logging 
    reduxDispatch(SETChileComponentName(getActivePage()));
    reduxDispatch(SETHasServiceRequestTypeLogData(true));
    reduxDispatch(SETLogEventData(
      {
        elementName: `${item.props.headerText} Tab`,
        elementId: "",
        action: "click",
        message: `User click on ${item.props.headerText} Tab `,
        profileSetting: `v2`,
      }
    ));

    reduxDispatch(SETIsCustomeLog(true));

    //End Event Logging
    props.setActiveTabKey(item.props.itemKey!);
  };
  const checkIris = () => {  
    if(isitemKey){
    if(!irisBot){
    const irisMenu = document.querySelector(".ms-layer");
    console.log(irisMenu, "irisMenu");
    if (irisMenu !== null) {
      reduxDispatch(setIsLoader(false));
      irisBot=true;
    } else {
      if(props.activeTabKey==='0')
        {
         reduxDispatch(setIsLoader(true));
        }
        else
        {
          reduxDispatch(setIsLoader(false));
        }
      setTimeout(checkIris, 30);
    }
  }
  else
  {
    reduxDispatch(setIsLoader(false));
  }
  }
  };

  const renderMain = (): JSX.Element => {
    return (
      <>
        {showErrorModal["isErrorModalVisible"] && (
          <Suspense fallback={<></>}>
            {" "}
            <ErrorPopUpMessage
              message={showErrorModal["errorMessage"]}
              isWorkspaceView={props.activeTabKey === "0"}
            />{" "}
          </Suspense>
        )}
       
        <Suspense fallback={<></>}>
          {" "}
          <Message type="success" />
          <AnnouncementBanner
            currentUserData={props.currentUserData}
            isWorkspaceView={props.activeTabKey === "0"}
          />
        </Suspense>

        <Pivot
          aria-label="Top navigation"
          selectedKey={props.activeTabKey}
          onLinkClick={setActiveTabKeyinHeaderTab}
          className="PivotClass"
          style={{ marginTop: "15px" }}>
          <PivotItem
            headerText={TAB_MYHELP_WORKSPACE}
            headerButtonProps={{
              "data-order": 1,
              "data-title": TAB_MYHELP_WORKSPACE,
            }}
            itemKey="0">
            <>{props.myHelpWorkspaceComponent}</>
          </PivotItem>
          <PivotItem headerText={TAB_CATALOG} itemKey="1">
            <>{props.catalogComnponent}</>
          </PivotItem>
        </Pivot>
      </>
    );
  };
  return renderMain();
};

export const V2_HeaderTabNav = injectIntl(HeaderTab);
