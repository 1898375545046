import React, { FC, useRef, useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, BrowserRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Stack, Text } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { classNamesFunction } from "@fluentui/react/lib/Utilities";
import axios from "axios";
import "../../../../app/components/header/AppHeader.css";
import ".././commonStyle.css";
import type {
  MessageData,
  PromptData,
} from "../../../../app/components/Chat/data";
import {
  IApplicationContext,
  ServiceContext,
  ExtensionsRegistrationClient,
  ApplicationContext,
  IUser,
} from "@msx/platform-services";
import {
  getExtensionsLoadingStatus,
  fetchExtensionsRegistration,
  getExtensionsRegistration,
  getCurrentLocale,
  setCurrentLocale,
  getCurrentTheme,
  setCurrentTheme,
  getCurrentAppState,
  getSelectedCategories,
  SETCurrUserData,
  setPreferenceChanged,
  setIsLoader,
  SetIsWwicCopilot,
  SetIsConversationId,
  SetInitialzeApp,
  getIsWwicCopilot,
} from "../../../store";
import { getDismissedAnnouncements } from "../../../../core/store";
import {
  fetchUserProfileBegin,
  fetchUserProfileSuccess,
  getUserProfileLoadingStatus,
  getUserDashboardTiles,
  fetchNotifications,
} from "../../../../app/store";

import { PageError, BusyIndicator } from "../../";

import { V2_AppHeader } from "../../../../app/components/header/V2component_Header/V2_AppHeader";
import { AppFooter } from "../../../../app/components";
import { appConfig } from "../../../../app/App.config";
import { getStyles } from ".././Shell.styles";
import { messages } from ".././Shell.messages";
import { getEnvVarUtils, getThemeByName } from "../../../utils";
import { Signin } from "../../../../app/pages";
import { DataServiceContext } from "../../../../app/context";
import "react-toastify/dist/ReactToastify.css";
import {
  getHeaderTokenFunc,
  putConsumptionAPI,
  getConsumptionAPI,
  postConsumptionAPI,
  // getConsumptionAPILocal,
  // postConsumptionAPILocal
} from "../../../../app/utils/httpUtils";
import ErrorBoundary from "../../../../errorHandling/errorBoundry";
import {
  getGraphTransitiveMemberOf,
  getGraphTransitiveMemberOfAi,
} from "../../../../app/pages/Authorize/AuthorizeUtility";
import apiService from "../../../../errorHandling/appService";
import { setTimeout } from "timers";
import EventLoggingProviderFC from "../../../../errorHandling/EventLoggingProviderFC";
import { GetTheTileConfiguration } from "../../../../app/components/TileConfiguration/V2_TileConfigurationHelperFile";
import PopUpModal from "../../../../app/components/main/Services/PopUpPanel";
import Loader from "../../../components/shell/Loader";
import { FeedbackScreenshotDownloader } from "../../../../app/components/feedback/FeedbackScreenshotDownloader";
import { FooterMain } from "../../../../app/components/footer/FooterMain";
import { getFormNames } from "../../../../app/components/TileConfiguration/V2_APIHelperFile";
import { Route } from "react-router";
import { Chat } from "../../../../app/components/Chat/Chat";
import ChatDocumentWrapper from "../../../../app/components/ChatDocument/ChatDocumentWrapper";
import ShimmerLoader from "../../../../app/components/main/Services/ShimmerLoader";
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";
import {
  NOT_AI_Authorised,
  NOT_AI_AUTHORISED_TWO,
} from "../../../../app/utils/constants";
import { V2_WhatsNew } from "../../../../app/components/Announcements/V2_Components/V2_Announcements";
import { V2_HeaderTabNav } from "../../../../app/components/header/V2component_Header/V2_HeaderTab";
import { V2_Main } from "../../../../app/components/main/V2component_main/V2_main";
import { V2_CatalogPage } from "../../../../app/components/CatalogPage/V2component_catalog/V2_CatalogPage";
import V2_Prompt from "../../Prompts/V2component_Prompt/V2_Prompt";
import { V2_ShellProps } from "./V2_Shell_types";
const getClassNames = classNamesFunction<any, any>();
let classes: any;
const defaultUser: IUser = {
  id: "",
  name: "",
  email: "",
};

const V2_ShellComponent: FC<V2_ShellProps> = (props) => {
  const { intl } = props;
  const reduxDispatch = useDispatch();
  const currentAppState = useSelector(getCurrentAppState);
  const SelectedCategoriesData = useSelector(getSelectedCategories);
  const currentTheme = useSelector(getCurrentTheme);
  const userDashboardTiles = useSelector(getUserDashboardTiles);
  const userProfileLoadingStatus = useSelector(getUserProfileLoadingStatus);
  const extensionsLoadingStatus = useSelector(getExtensionsLoadingStatus);
  const [showFabricComponent, setShowFabricComponent] = useState(false);
  const extensionsRegistration = useSelector(getExtensionsRegistration);
  const isWwicCopilotOpen = useSelector(getIsWwicCopilot);
  const extensionsRegistrationClient = new ExtensionsRegistrationClient(
    extensionsRegistration
  );
  const [
    isStartupError,
    { setTrue: activateStartupError, setFalse: dismissStartupError },
  ] = useBoolean(false);
  const [startupError, setStartupError] = useState("");
  const currentLocale = useSelector(getCurrentLocale);
  const currentLocaleRef = useRef(currentLocale);
  // const [isUserLoggedIn, setIsUserLoggedIn] = useState(props.isUserLogIn);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const isUserLoggedInRef = useRef(isUserLoggedIn);
  const { authClient } = useContext(ServiceContext);
  const [isNavCollapsed] = useState(false);
  const [currentUser, setCurentUser] = useState(defaultUser);
  const { dataService } = useContext(DataServiceContext);
  const [showBotinShell, setshowBotinShell] = useState(true);
  const [currentUserDbId, setCurentUserDbId] = useState(0);
  const [currentUserData, setCurrentUserData] = useState();
  const [userDataCreated, setUserDataCreated] = useState(false);

  const context = React.useContext(ServiceContext);
  const [addServiceId, setAddServiceId] = useState(0);
  const [isPageLoad, setIsPageLoad] = useState(true);
  //setting the first pivot tab  as default
  const [selectedActiveTab, setselectedActiveTab] = useState("0");
  const [iriS_Utterance, setIris_Utterance] = useState("");
  const [tileName, setTile_Name] = useState("");
  const [isUserAuthorized, setUserAuthorized] = useState(false);
  const [isUserAuthorizedAi, setUserAuthorizedAi] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [irisBotIconClicked, setIrisBotIconClicked] = React.useState(false);
  const [irisAppName, set_irisAppName] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  //catalog page related states
  const [servicesAdded, setServicesAdded] = useState(false);
  const [catalogServicesResponse, setCatalogServicesResponse] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [serviceDeleted, setServiceDeleted] = useState(false);
  const [singleServiceAdded, setSingleServiceAdded] = useState(false);

  const [getIrisToken] = useState(null);
  const [getAccessTokenForAPICall, setAccessTokenForAPIcall] = useState(null);
  const [previousUserData, setPreviousUserData] = useState(currentUserData);
  const [userDataChanged, setUserDataChanged] = useState(false);
  const [currentUserIsWelcomeMessage, setCurrentUserIsWelcomeMessage] =
    useState(null);
  const [isCheckedMessage] = useState(false);
  const [chatBotClicked, setChatBotClicked] = useState(false);
  const [formDataToIris, setFormDataToIris] = useState(null);
  const [isDefaultPrefSet, setIsDefaultPrefSet] = useState(false);
  const [isPresetModal, setPresetModal] = useState(false);
  const [isProfileLoading, setProfileLoading] = useState(false);
  // Removed unused state variables
  const [isAmericasPref, setAmericasPref] = useState(false);
  const [isAmericasSMCPref, setAmericasSMCPref] = useState(false);
  const [isParamerterLoad, setParamerterLoad] = useState("");
  const [isCanceledPreset, setCanceledPreset] = useState(false);
  const [isSasLoaded, setSasLoaded] = useState(false);
  const [isSasCalled, setSasCalled] = useState(false);
  const [reccomendedData, setReccomendedData] = useState([]);
  const [recommendationLoaded, setRecommendationLoaded] = useState(false);

  const [isAiUser, setIsAiUser] = useState(false);
  const [isAiServicesEnabled, setIsAiServicesEnabled] = useState(false);

  const [Area, setArea] = useState("");
  //   const[Role , setRole] = useState("");
  const [Segment, setSegment] = useState("N/A");
  const [SubSegment, setSubSegment] = useState("N/A");
  const [messageHistory, setMessageHistory] = React.useState<MessageData[]>([]);
  const [envVariableCalled, setEnvVarCalled] = useState(false);
  const [envVariableLoaded, setEnvVarLoaded] = useState(false);
  //Multipage Welcome exp
  const theme = useSelector(getCurrentTheme);
  classes = getClassNames(getStyles, theme);
  let dismissedAnnouncements = useSelector(getDismissedAnnouncements);
  const [openedThroughMenu] = useState(false);
  const [hideDialog, setHideDialog] = useState(true);
  const [filteredAnnouncementss, setfilteredAnnouncementss] = useState(null);
  const [finalTileObject, setFinalTileObject] = useState({
    isIncidentTile: false,
    isNonAmerica: false,
    isAmerica: false,
    isAmericaSMC: false,
    isValidated: false,
    isFeedbackUrlDownload: false,
    isLoaded: false,
    TileName: "",
    IrisUtterance: "",
    Area: "",
    Segment: "",
    SubSegment: "",
    Mode: "",
    downloadfile: "",
    typeOfFeedback: "",
    Error: "",
    loadForms: false,
    tileinfo: false,
    sendServiceGroup: false,
    defaultServiceGroup: "",
  });
  const [prefrenceRefresh, setPrefrenceRefresh] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState({
    isErrorModalVisible: false,
    errorMessage: "",
  });
  const [loadedVisibility, setLoadedVisibility] = useState(false);
  const [openTheSegmentPopUp, setTheSegmentPopUp] = useState(false);
  classes = getClassNames(getStyles, currentTheme);
  const apiEndPoint = `${process.env.REACT_APP_Consumption_API_ENDPOINT}`;
  const apiEndPointLocal = `https://localhost:44348`;
  const APP_VERSION = process.env.APP_VERSION || "v1.0.0";
  const WEBFORMNAMES_LOCAL_STORAGE_KEY = `v2_webFormNames_${APP_VERSION}`;
  const [announcementJsondataa, setAnnouncementJsondataa] = useState(null);
  const [isIrisLoader, setIsIrisLoader] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [isCopilot, setIsCopilot] = useState(false);
  const [loadingPrompt, setLoadingPrompt] = useState(true);
  const [Arr, setArr] = useState(false);
  const [Category, setCategory] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const sastoken = sessionStorage.getItem("SasTokenKey");
  const [userWelcomeUpdated, setUserWelcomeUpdated] = useState(false);

  setTimeout(() => {
    const button = document.querySelector(
      'button[title="Start Over"]'
    ) as HTMLButtonElement;
    if (button) {
      button.addEventListener("click", handleChatBotIconClick);
    }
  }, 3000);

  // 1. useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = `${
          process.env.REACT_APP_BLOBSASSTART +
          "announcement/json/announcement.json" +
          sastoken
        }`;

        const response = await fetch(jsonData, {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        });
        const data = await response?.json();
        console.log(data);
        SetAnnouncmentData(data);
      } catch (error) {
        const jsonData = await dataService?.GetAnnouncements();
        SetAnnouncmentData(jsonData);
        console.log(
          "error to fetch data from blob fetching from local.",
          error
        );
      }
    };
    fetchData();
  }, [dataService]);

  //2. useEffect
  useEffect(() => {
    //MOUNT
    sessionStorage.removeItem("activeTabKey");
    loadStartupData();
    window.removeEventListener("resize", handleResize);
    window.addEventListener("resize", handleResize);
    //UNMOUNT
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //3. UseEffect
  useEffect(() => {
    if (isUserLoggedIn) {
      getConfig();
      getDefaultAmericasPref("Get").then(() => {
        getForm();
        setLoadedVisibility(true);
      });
    }
    // eslint-disable-next-line
  }, [isUserLoggedIn]);

  //4.effect
  useEffect(() => {
    if (currentUserData !== previousUserData) {
      setUserDataChanged(true);
      setPreviousUserData(currentUserData);
    } else {
      setUserDataChanged(false);
    }
  }, [currentUserData]);

  //5.effect
  useEffect(() => {
    if (isUserLoggedIn && currentUser.email !== "") {
      let fetchData = async () => {
        getAccessTokenForAPICall ??
          (await setAccessTokenForAPIcall(
            await getHeaderTokenFunc(
              context.authClient,
              getAccessTokenForAPICall
            )
          ));

        if (getAccessTokenForAPICall !== null) {
          //   if (sessionStorage.getItem("AreaName") === null && sessionStorage.getItem("RoleName") === null) { await getDefaultAmericasPref("Get"); }
          if (sessionStorage.getItem("AreaName") === null) {
            await getDefaultAmericasPref("Get");
          }

          await getUserIDByEmailId();
          //load default prefrence for Americas
        }
      };
      fetchData();
    }
  }, [
    context,
    isUserLoggedIn,
    currentUser,
    getAccessTokenForAPICall,
    prefrenceRefresh,
  ]);

  //6.effect
  useEffect(() => {
    if (currentUserDbId > 0) {
      fetchDataAndUpdateState();
    }
  }, [SelectedCategoriesData]);

  //7.effect
  useEffect(() => {
    if (currentUserDbId > 0) {
      fetchDataAndUpdateState();
      isPrompt && getPrompts();
      setServiceDeleted(false);
      setSingleServiceAdded(false);
      RequestAccessValidatorFunc();
      RequestAccessValidatorForAi();
    }
  }, [
    context,
    servicesAdded,
    currentUserData,
    serviceDeleted,
    singleServiceAdded,
  ]);

  //8.effect
  useEffect(() => {
    if (sessionStorage.getItem("SasTokenKey")) {
      const clearSessionStorageByKey = () => {
        sessionStorage.removeItem("SasTokenKey");
        setSasLoaded(false);
        getStorageSasToken();
      };

      // Set up the timeout to clear the session storage in 59 minutes (3540 seconds)
      setTimeout(clearSessionStorageByKey, 60 * 60 * 1000);
      const handleOffline = () => {
        clearSessionStorageByKey();
      };

      // Add event listeners for offline and online events
      window.addEventListener("offline", handleOffline);

      // Cleanup function
      return () => {
        window.removeEventListener("offline", handleOffline);
      };
    }

    if (sessionStorage.getItem("EnvironmentVariables")) {
      const clearSessionStorageByKey = () => {
        sessionStorage.removeItem("EnvironmentVariables");
        setEnvVarLoaded(false);
        getEnvVariables();
      };

      // Set up the timeout to clear the session storage in 59 minutes (3540 seconds)
      setTimeout(clearSessionStorageByKey, 60 * 60 * 1000);
      const handleOffline = () => {
        clearSessionStorageByKey();
      };

      // Add event listeners for offline and online events
      window.addEventListener("offline", handleOffline);

      // Cleanup function
      return () => {
        window.removeEventListener("offline", handleOffline);
      };
    }

    const handleOnline = () => {
      if (!sessionStorage.getItem("SasTokenKey")) {
        getStorageSasToken();
      }
      if (!sessionStorage.getItem("EnvironmentVariables")) {
        getEnvVariables();
      }
    };
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("online", handleOnline);
    };
  }, [isSasLoaded, envVariableLoaded, getAccessTokenForAPICall?.token]);

  //9.effect
  useEffect(() => {
    if (finalTileObject["isAmerica"] || finalTileObject["isAmericaSMC"]) {
      if (finalTileObject.isLoaded !== true) {
        isCheckAmericasPref();
      }
    }
  }, [
    finalTileObject.isAmerica,
    finalTileObject.isAmericaSMC,
    isAmericasSMCPref,
    isAmericasPref,
    currentUserDbId,
  ]);

  //10.effect
  useEffect(() => {
    isValidParameterComing();
  }, [finalTileObject["Error"]]);

  //11.effect
  useEffect(() => {
    const fetchUserData = async () => {
      await getUserIDByEmailId();
      if(userWelcomeUpdated){
        reduxDispatch(setIsLoader(true));
      }
    };
    if (userDataCreated === true || userWelcomeUpdated) {
      fetchUserData();
    }
  }, [userDataCreated, userWelcomeUpdated]);

  //data for 2 useeffect
  const loadStartupData = async () => {
    const isLoggedIn = await authClient.isLoggedIn();
    setIsUserLoggedIn(isLoggedIn);
    if (isLoggedIn) {
      const user = await authClient.getUser();
      setCurentUser(user);
    }

    if (!isLoggedIn) {
      return;
    }

    const Window = window as any;
    if (
      Window.hasStartupDataLoaded === true ||
      Window.hasStartupDataInprogress === true
    ) {
      return;
    }

    Window.hasStartupDataInprogress = true;
    try {
      dismissStartupError();
      // load user profile
      if (appConfig.enableUserSettingsApi) {
        reduxDispatch(fetchUserProfileBegin());
        const userProfile = await dataService.GetUserProfile();
        reduxDispatch(fetchUserProfileSuccess(userProfile));
        const locale = userProfile.userPreference.locale;
        if (locale !== currentLocaleRef.current) {
          reduxDispatch(setCurrentLocale(locale));
        }
        const themeName = userProfile.userPreference.theme;
        if (currentTheme.name !== themeName) {
          const newTheme = getThemeByName(themeName);
          reduxDispatch(setCurrentTheme(newTheme));
        }
      }

      // load notifications
      if (appConfig.notificaitonConfig.active) {
        reduxDispatch(fetchNotifications(dataService));
      }

      // load extension registraton
      if (appConfig.registrationConfig.active) {
        reduxDispatch(fetchExtensionsRegistration(dataService, []));
      }
      Window.hasStartupDataLoaded = true;
      Window.hasStartupDataInprogress = false;
    } catch (error) {
      Window.hasStartupDataInprogress = false;
      setStartupError(JSON.stringify(error));
      activateStartupError();
    }
  };

  //data for 3 useeffect
  const getConfig = async () => {
    if (finalTileObject["isValidated"] && !isParamerterLoad) {
      const finalTile = GetTheTileConfiguration(finalTileObject, context);
      setFinalTileObject(await finalTile);
      setParamerterLoad("true");
    }
  };

  const getForm = async () => {
    let webFormNames: any[] = [];
    const storedWebFormNames = localStorage.getItem(
      WEBFORMNAMES_LOCAL_STORAGE_KEY
    );
    if (storedWebFormNames) {
      try {
        webFormNames = JSON.parse(storedWebFormNames);
      } catch (error) {
        console.error("Error parsing JSON from localStorage:", error);
      }
    }

    if (webFormNames.length === 0) {
      // Fetch new data since it's not available in localStorage
      webFormNames = await getFormNames(context);
      // Store the result in localStorage with the versioned key
      if (webFormNames?.length > 0) {
        localStorage.setItem(
          WEBFORMNAMES_LOCAL_STORAGE_KEY,
          JSON.stringify(webFormNames)
        );
      }
      // localStorage.setItem(WEBFORMNAMES_LOCAL_STORAGE_KEY, JSON.stringify(webFormNames));
    }
  };

  //data for 6 & 7 useeffect
  const fetchDataAndUpdateState = async () => {
    try {
      if (context) {
        let data: {
          AreaFilter: string[] | null;
          mcemFilter: string[] | null;
          categoryFilter: string[] | null;
        } = {
          AreaFilter: SelectedCategoriesData["Areas"] &&
          SelectedCategoriesData["Areas"].length > 0
            ? SelectedCategoriesData["Areas"].toString()
            : null,
          mcemFilter:
            SelectedCategoriesData["MCEM Stage"] &&
            SelectedCategoriesData["MCEM Stage"].length > 0
              ? SelectedCategoriesData["MCEM Stage"].toString()
              : null,
          categoryFilter:
            SelectedCategoriesData["Category"] &&
            SelectedCategoriesData["Category"].length > 0
              ? SelectedCategoriesData["Category"].toString()
              : null,
        };
        const headers: any = await getHeaderTokenFunc(
          context.authClient,
          getAccessTokenForAPICall.token
        );
        const response = await apiService(
          `${apiEndPoint}/api/V2_Service/GetAllServices`,
          "POST",
          data,
          headers.request.headers
        );
        if (response?.data) {
          setCatalogServicesResponse(response);
          sessionStorage.setItem("v2_services", JSON.stringify(response.data));
        } else {
          throw new Error("Failed to fetch data.");
        }
      }
    } catch (error) {}
  };

  const RequestAccessValidatorFunc = async () => {
    if (isUserLoggedIn && !isUserAuthorized) {
      try {
        if (context?.telemetryClient) {
          const response = await getGraphTransitiveMemberOf(context.authClient);
          console.log(response, "response of graph api");
          if (response?.status === 200) {
            setUserAuthorized(response.data.value?.length > 0);

            if (response.data.value?.length == 0) {
              const responsefromLogicAppAPI = await GetUserGroupsByOId();
              console.log(
                responsefromLogicAppAPI?.length,
                "responsefromLogicAppAPI length"
              );
              setUserAuthorized(responsefromLogicAppAPI?.length > 0);
            }
          }
        }
      } catch (error) {}
    }
  };
  const RequestAccessValidatorForAi = async () => {
    if (isUserLoggedIn && !isUserAuthorizedAi) {
      try {
        if (context?.telemetryClient) {
          let EnvVariables = getEnvVarUtils();
          const groupID = EnvVariables.find(
            (item) => item.key === "REACT_APP_AI_GROUP_ID"
          )?.value;
          const group = EnvVariables.find(
            (item) => item.key === "REACT_APP_AI_GROUP"
          )?.value;
          const response = await getGraphTransitiveMemberOfAi(
            context.authClient,
            group
          );
          if (response?.status === 200) {
            setUserAuthorizedAi(response.data.value?.length > 0);
            if (response.data.value?.length == 0) {
              const responsefromLogicAppAPI = await GetUserGroupsByOIdAi(
                groupID
              );
              setUserAuthorizedAi(responsefromLogicAppAPI?.length > 0);
            }
          }
        }
      } catch (error) {}
    }
  };

  const GetUserGroupsByOId = async () => {
    try {
      const OID = currentUser.userObject.localAccountId;
      const groupID = `${process.env.REACT_APP_USER_AUTH_GROUP_ID}`;

      if (context?.telemetryClient) {
        const url = `${apiEndPoint}/api/GetGraphUserGroups/GetGraphUserGroupsFromLogicApp/${OID}/${groupID}`;

        const headers: any = await getHeaderTokenFunc(
          context.authClient,
          getAccessTokenForAPICall.token
        );
        const response = await axios.get(url, {
          headers: headers.request.headers,
        });
        if (response.status === 200) {
          return response.data;
        } else if (response?.status === 404 || response?.status === 204) {
          handleNotFoundResponse();
        }
      }
    } catch (error) {
      return error?.response || error;
    }
  };

  const GetUserGroupsByOIdAi = async (groupID) => {
    try {
      const OID = currentUser.userObject.localAccountId;
      if (context?.telemetryClient) {
        const url = `${apiEndPoint}/api/GetGraphUserGroups/GetGraphUserGroupsFromLogicApp/${OID}/${groupID}`;

        const headers: any = await getHeaderTokenFunc(
          context.authClient,
          getAccessTokenForAPICall.token
        );
        const response = await axios.get(url, {
          headers: headers.request.headers,
        });
        if (response.status === 200) {
          return response.data;
        } else if (response?.status === 404 || response?.status === 204) {
          handleNotFoundResponse();
        }
      }
    } catch (error) {
      return error?.response || error;
    }
  };

  //data for 8 useeffect
  const getStorageSasToken = async () => {
    try {
      const sastokenresponse = await getConsumptionAPI(
        `/api/User/GetSasTokenKey`,
        context.authClient
      );
      if (sastokenresponse.status === 200) {
        sessionStorage.setItem(
          "SasTokenKey",
          "?" + sastokenresponse.data.split("{_URL:}")[0]
        );
        setSasLoaded(true);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const getEnvVariables = async () => {
    try {
      const response = await getConsumptionAPI(
        `/api/User/GetEnvironmentVariables`,
        context.authClient
      );
      if (response.status === 200) {
        sessionStorage.setItem(
          "EnvironmentVariables",
          JSON.stringify(response?.data)
        );
        setEnvVarLoaded(true);
        // setSasLoaded(true);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleResize = (): void => {
    // do something
  };

  //data for 9 useeffect
  const isCheckAmericasPref = () => {
    // Removed unused variables
    // if (isAmericasPref || isAmericasSMCPref) {
    //   setPresetModal(false);
    //   setIsDefaultPrefSet(true);
    //   finalTileObject.loadForms = true;
    // } else if (currentUserDbId > 0) {
    //   finalTileObject.loadForms = false;
    //   if (isDefaultPrefSet === false) {
    //     setPresetModal(true);
    //   }
    // }

    setProfileLoading(true);
    setPresetModal(false);
    setIsDefaultPrefSet(true);
    finalTileObject.loadForms = true;
  };

  //data for 10useeffect
  const isValidParameterComing = () => {
    if (
      finalTileObject["Error"] !== undefined &&
      finalTileObject["Error"] !== ""
    ) {
      setShowErrorModal({
        isErrorModalVisible: true,
        errorMessage: finalTileObject["Error"],
      });
    }
  };

  //header code
  const handleFabricVisibilityChange = (isVisible: boolean) => {
    setShowFabricComponent(isVisible);
  };

  const getDisplayLocales = () => {
    let result = [];
    if (!appConfig.languageConfig.active) {
      return result;
    }
    const locales = appConfig.languageConfig.locales;
    Object.keys(locales).forEach(function (key, idx) {
      result.push({
        key: key,
        text: locales[key],
      });
    });
    return result;
  };

  const handleLocaleChange = (locale) => {
    reduxDispatch(setCurrentLocale(locale));
  };

  const [userEmailCalled, setUserEmailCalled] = useState(false);
  const getUserIDByEmailId = async () => {
    try {
      const user = await authClient.getUser();
      if (context?.telemetryClient) {
        const url = `${apiEndPoint}/api/V2_User/GetUserByEmailId/${user.email}/${user.userObject.localAccountId}`;
        const headers: any = await getHeaderTokenFunc(
          context.authClient,
          getAccessTokenForAPICall.token
        );
        const response = await axios.get(url, {
          headers: headers.request.headers,
        });
        if (response.status === 200) {
          handleSuccessResponse(response.data);
          setUserEmailCalled(true);
          setIsAiUser(response.data.isAiUser);
          setIsAiServicesEnabled(response.data.isAiServicesEnabled);
        } else if (response?.status === 404 || response?.status === 204) {
          handleNotFoundResponse();
          setUserEmailCalled(true);
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleSuccessResponse = async (data) => {
    if (data.userArea === null) {
      setIsOpen(true);
      setTheSegmentPopUp(true);
      setCurrentUserIsWelcomeMessage(data?.isWelcomeMessage);
      setCurentUserDbId(data.id);
      sessionStorage.setItem("_announcement", data.annoucementKey);
    } else {
      //removed unused setting of state variables
      setAmericasPref(data.isAmerica);
      setAmericasSMCPref(data.isAmericaSMC);
      setCurentUserDbId(data.id);
      setCurrentUserData(data);
      setCurrentUserIsWelcomeMessage(data?.isWelcomeMessage);
      sessionStorage.setItem("_announcement", data.annoucementKey);
      if (
        finalTileObject["isValidated"] &&
        finalTileObject["isFeedbackUrlDownload"]
      ) {
        const feedbackResult = await FeedbackScreenshotDownloader(
          finalTileObject,
          context,
          isUserLoggedIn
        );
        setFinalTileObject(feedbackResult);
      }
      if (!finalTileObject["isValidated"] && !isParamerterLoad) {
        const finalTile = GetTheTileConfiguration(finalTileObject, context);
        setFinalTileObject(await finalTile);
        setParamerterLoad("true");
      }
     // setReccomendedForUser(data.userAreaName);
      setArea(data.userAreaName);
    }
    reduxDispatch(SETCurrUserData(data));
  };

  const setReccomendedForUser = async (area) => {
    try {
      const apiUrl = "/api/V2_UserWorkSpace/SetReccomendedTile";
      const postData = {
        Upn: currentUser.email,
        Area: area,
      };

      const response = await postConsumptionAPI(
        apiUrl,
        postData,
        context.authClient
      );

      if (response.status >= 200 && response.status < 300) {
        setReccomendedData(response.data);
      }
    } catch (error) {
      console.error("An error occurred while fetching the API.", error);
    } finally {
      setRecommendationLoaded(true);
    }
  };

  const handleChatBotIconClick = async () => {
    setIrisBotIconClicked(true);
    setIris_Utterance("");
    setTile_Name("");
    if (selectedActiveTab === "0") {
      setTimeout(() => {
        const panel = document.querySelector("#headerTab");

        if (panel !== null) {
          let ele = panel.parentNode.parentNode.parentNode as HTMLElement;
          ele.style.zIndex = "200000000";
        } else {
          const iris = document.querySelector("#irisMenu");

          if (iris !== null) {
            let ele = iris.parentNode.parentNode.parentNode.parentNode
              .parentNode.parentNode.parentNode.parentNode.parentNode
              .parentNode as HTMLElement;
            ele.style.zIndex = "-1";
          }
        }
      }, 3000);
    }
  };

  const renderAppHeader = () => {
    return (
      <V2_AppHeader
        showFabricComponent={showFabricComponent}
        onFabricVisibilityChanged={handleFabricVisibilityChange}
        locales={getDisplayLocales()}
        dispatchUpdateLocaleAction={handleLocaleChange}
        isAppReady={currentAppState.isReady}
        theme={currentTheme}
        appName={appConfig.appName}
        isUserLoggedIn={isUserLoggedIn}
        user={currentUser}
        currentUserData={currentUserData}
        getUserIDByEmailId={getUserIDByEmailId}
        handleChatBotIconClick={handleChatBotIconClick}
        selectedActiveTab={selectedActiveTab}
        isIrisLoader={isIrisLoader}
        isPrompt={isPrompt}
      />
    );
  };

  //welcome page
  const SetAnnouncmentData = async (data) => {
    setAnnouncementJsondataa(data);
    const FilterData = filterAndSort(data?.Announcements);
    FilterData?.filter(
      (announcement) =>
        !dismissedAnnouncements.some(
          (dismissed) => dismissed.id === announcement.id
        )
    );
    setfilteredAnnouncementss(FilterData);
    !!FilterData?.length && setHideDialog(false);
  };

  const filterAndSort = (announcements) => {
    return announcements
      ?.filter((announcement) => {
        const startDate = new Date(announcement.startDate);
        const endDate = new Date(announcement.endDate);
        let currentDate = new Date();
        return endDate > currentDate && startDate <= currentDate;
      })
      .sort((a, b) => {
        const dateA = new Date(a.startDate).getTime();
        const dateB = new Date(b.startDate).getTime();
        return dateB - dateA;
      });
  };

  const toggleHideDialog = (dontShowAgain: boolean) => {
    if (dontShowAgain) {
      announcementJsondataa.Announcements.forEach((val) =>
        dismissedAnnouncements.push({ id: val.id, expiryDate: val.endDate })
      );

      localStorage.setItem(
        "dismissedAnnouncements",
        JSON.stringify(dismissedAnnouncements)
      );
    }
    setHideDialog(!hideDialog);
  };

  const handleChildStateChange = (newState, check) => {
    // Removed unused state variables
    setTheSegmentPopUp(true);
    setUserDataCreated(true);
    setCurrentUserIsWelcomeMessage(check);
  };

  const renderMultiPageWelcomeExp = () => {
    if (
      !finalTileObject["isValidated"] &&
      currentUserDbId === 0 &&
      context.authClient["account"] != null &&
      !isParamerterLoad
    ) {
      setParamerterLoad("true");
      GetTheTileConfiguration(finalTileObject, context);
    }
    return (
      <V2_WhatsNew
        filteredAnnouncements={
          filteredAnnouncementss !== null ? filteredAnnouncementss : []
        }
        hideDialog={hideDialog}
        toggleHideDialog={toggleHideDialog}
        openedThroughMenu={openedThroughMenu}
        title={
          !!filteredAnnouncementss?.length ? announcementJsondataa.title : ""
        }
        subText={
          !!filteredAnnouncementss?.length ? announcementJsondataa.subTitle : ""
        }
        currentUserData={currentUserData}
        currentUserDbId={currentUserDbId}
        currentUserIsWelcomeMessage={currentUserIsWelcomeMessage}
        handleChildStateChange={handleChildStateChange}
        currentUser={currentUser}
        setUserWelcomeUpdated={setUserWelcomeUpdated}
      />
    );
  };

  //segment popup
  const getDefaultAmericasPref = async (action) => {
    try {
      if (context?.telemetryClient) {
        let url = "";
        if (action === "update") {
          setIsDefaultPrefSet(true);
        }
        if (finalTileObject["isAmericaSMC"]) {
          url = `${apiEndPoint}/api/V2_Service/GetDefaultAmericasSMCPref`;
        } else {
          url = `${apiEndPoint}/api/V2_Service/GetDefaultAmericasPref`;
        }
        const headers: any = await getHeaderTokenFunc(
          context.authClient,
          getAccessTokenForAPICall.token
        );
        const response = await axios.get(url, {
          headers: headers.request.headers,
        });
        if (response?.status === 200) {
          sessionStorage.setItem("AreaName", response.data?.areaName);
          sessionStorage.setItem(
            "CheckVisibility",
            response.data?.checkVisibility
          );

          if (action === "update") {
            PresetPreference(response.data?.areaName);
          }
        } else if (response?.status === 404 || response?.status === 204) {
          handleNotFoundResponse();
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  const PresetPreference = async (area) => {
    try {
      const apiUrl = "/api/V2_User/PresetPreference";
      const postData = {
        //UPN: currentUser.email,
        //Oid: currentUser.userObject.localAccountId,
        UserArea: area,
        DataverseRowID: "3f5a37dc-8d95-4232-85a7-990dab5888a9",
        IsActive: 1,
        IsWelcomeMessage: currentUserIsWelcomeMessage,
      };

      const response = await putConsumptionAPI(
        apiUrl,
        postData,
        context.authClient
      );

      if (response.status >= 200 && response.status < 300) {
        setCurentUserDbId(response.data);
        setPrefrenceRefresh(true);
        reduxDispatch(setPreferenceChanged(false));
        if (finalTileObject["isAmerica"]) {
          setTimeout(() => {
            finalTileObject.loadForms = true;
          }, 500);
        }
        if (finalTileObject["isAmericaSMC"]) {
          setTimeout(() => {
            finalTileObject.loadForms = true;
          }, 500);
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching the API.", error);
    }
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const updateUser = async (area) => {
    try {
      const apiUrl = "/api/V2_User/Update";
      const postData = {
        //UPN: currentUser.email,
        Oid: currentUser.userObject.localAccountId,
        UserArea: area,
        UserADGroupID: [
          {
            userADGroupID: 1,
          },
        ],
        DataverseRowID: "3f5a37dc-8d95-4232-85a7-990dab5888a9",
        IsActive: 1,
        IsWelcomeMessage: currentUserIsWelcomeMessage,
      };

      const response = await putConsumptionAPI(
        apiUrl,
        postData,
        context.authClient
      );

      if (response.status >= 200 && response.status < 300) {
        setCurentUserDbId(response.data);
        setPrefrenceRefresh(true);
      }
    } catch (error) {
      console.error("An error occurred while fetching the API.", error);
    }
  };

  //routes
  const handleConfirmPreset = () => {
    if (
      (finalTileObject["isAmerica"] || finalTileObject["isAmericaSMC"]) &&
      isDefaultPrefSet === false
    ) {
      setProfileLoading(true);
      setPresetModal(false);
      getDefaultAmericasPref("update");
    }
  };

  const hideConfirmModel = () => {
    setPresetModal(false);
    setCanceledPreset(true);
  };

  const closeCopilot = () => {
    // setIsCopilotOpen(false);
    reduxDispatch(SetIsWwicCopilot(false));
    reduxDispatch(SetIsConversationId(false));
    reduxDispatch(SetInitialzeApp(false));
  };

  const setActiveTabKeyinShell = (activePivotKey) => {
    setIsPageLoad(false);
    const activetabkey = (Number(activePivotKey) % 2).toLocaleString();
    if (activetabkey === "0") {
      setIsIrisLoader(false);
    }
    setselectedActiveTab(activetabkey);
    sessionStorage.setItem("activeTabKey", activetabkey);
  };

  const resetShellIrisContext = () => {
    setIris_Utterance(null);
    set_irisAppName(null);
  };

  const handleIrisLoading = async (loader) => {
    setIsIrisLoader(loader);
  };

  const renderRoutes = () => {
    const Window = window as any;

    if (Window.hasStartupDataInprogress === true) {
      return null;
    }

    if (!isUserLoggedIn) {
      if (appConfig.loginOnStartup) return null;
      if (context && !loadedVisibility)
        return <Signin isUserLoggedIn={isUserLoggedIn} />;
    }

    if (currentUserDbId !== 0) {
      return (
        <Stack className="main-container-shell">
          <Loader
            isLoading={isProfileLoading}
            setProfileLoading={setProfileLoading}
          />
          <PopUpModal
            isVisible={isPresetModal}
            onConfirm={handleConfirmPreset}
            onCancel={hideConfirmModel}
            onHide={hideConfirmModel}
            dialogTitle={"Reset Preference Confirmation"}
            dialogSubText={
              "You are identified as Non-Americas User, would you like to reset your My Help Workspace to Americas services?"
            }
            buttonsCount={2}
            buttonTextOne={"Yes"}
            buttonTextTwo={"No"}
          />

          {!isWwicCopilotOpen && window.location.pathname !== '/wwicai' && (<V2_HeaderTabNav
            setActiveTabKey={setActiveTabKeyinShell}
            activeTabKey={selectedActiveTab}
            showErrorModal={showErrorModal}
            setShowErrorModal={setShowErrorModal}
            currentUserData={currentUserData}
            catalogComnponent={
              <ErrorBoundary componentName="Services">
                <V2_CatalogPage
                  setActiveTabKey={setActiveTabKeyinShell}
                  setIrisContext={setIris_Utterance}
                  resetCatalogIrisContext={resetShellIrisContext}
                  userId={currentUserDbId}
                  setAddServiceId={setAddServiceId}
                  catalogServicesResponse={catalogServicesResponse}
                  setServicesAdded={setServicesAdded}
                  isLoading={isLoading}
                  setSearchTerm={setSearchTerm}
                  setSingleServiceAdded={setSingleServiceAdded}
                  handleIrisLoading={handleIrisLoading}
                  currentUserData={currentUserData}
                />
              </ErrorBoundary>
            }
            myHelpWorkspaceComponent={
              <>
                <V2_Main
                  isPageLoad={isPageLoad}
                  setActiveTabKey={setActiveTabKeyinShell}
                  userId={currentUserDbId}
                  userDataChanged={userDataChanged}
                  currentUserData={currentUserData}
                  setIrisUtterance={setIris_Utterance}
                  setTileName={setTile_Name}
                  setIrisAppName={set_irisAppName}
                  isUserLoggedIn={isUserLoggedIn}
                  addServiceId={addServiceId}
                  setAddServiceId={setAddServiceId}
                  setServiceDeleted={setServiceDeleted}
                  setFormDataToIris={setFormDataToIris}
                  setShowBot={setshowBotinShell}
                  setChatBotClicked={setChatBotClicked}
                  finalTileObject={finalTileObject}
                  setProfileLoading={setProfileLoading}
                  isCanceledPreset={isCanceledPreset}
                  showErrorModal={showErrorModal}
                  setShowErrorModal={setShowErrorModal}
                  reccomendedData={reccomendedData}
                  recommendationLoaded={recommendationLoaded}
                  setRecommendationLoaded={setRecommendationLoaded}
                  setSubSegment={setSubSegment}
                  setSegment={setSegment}
                  userWelcomeUpdated={userWelcomeUpdated}
                  isAiEnabled={isAiUser && isAiServicesEnabled}
                />
              </>
            }></V2_HeaderTabNav>)}

        {(isWwicCopilotOpen || window.location.pathname === '/wwicai') && (isAiUser === true && isAiServicesEnabled === true) && (
            <>
              <FluentProvider theme={teamsLightTheme}>
                <ChatDocumentWrapper
                  setFormDataToIris={setFormDataToIris}
                  setShowBot={setshowBotinShell}
                  setChatBotClicked={setChatBotClicked}
                  setIrisUtterance={setIris_Utterance}
                  setIrisAppName={set_irisAppName}
                  setTileName={setTile_Name}
                  setMessageData={messageHistory}
                  setIrisPayload={irisPayload}
                  closeCopilot={closeCopilot}
                  userEmail={currentUser.email}
                />
              </FluentProvider>
            </>
          )}

          {(window.location.pathname === '/wwicai' && (isAiUser === false || isAiServicesEnabled === false)) && (
            <Stack verticalAlign="center" styles={{ root: { alignItems: "left", marginTop: "20%", marginLeft: "10%" } }}>
            <Text styles={{ root: { fontSize: "large", fontWeight: "bold", textAlign: "left" } }}>
              {NOT_AI_Authorised}
            </Text>
            <Text styles={{ root: { fontSize: "large", fontWeight: "bold", textAlign: "left" } }}>
              {NOT_AI_AUTHORISED_TWO}
            </Text>
          </Stack>
          )}

          {/* <>HeaderTabNav</> */}
        </Stack>
      );
    }

    if (isStartupError) {
      return <PageError error={startupError} />;
    }
    if (appConfig.enableUserSettingsApi && userProfileLoadingStatus) {
      return (
        <BusyIndicator
          message={intl.formatMessage(messages.loadingUserProfile)}
        />
      );
    }
    if (appConfig.registrationConfig.active) {
      if (extensionsLoadingStatus) {
        return (
          <BusyIndicator
            message={intl.formatMessage(messages.loadingExtensionRegistraton)}
          />
        );
      }
    }
    return props?.onRenderRoutes(
      extensionsRegistrationClient.getExtensionsPages()
    );
  };

  //footer - iris
  const renderAppFooter = () => {
    if (selectedActiveTab === "1") {
      return null;
    }
    const isDataArray = currentUserData && typeof currentUserData === "object";

    if (currentUserDbId !== 0 && isUserAuthorized && isDataArray) {
      return (
        <AppFooter
          IsUserAuthorized={isUserAuthorized}
          ShowBot={showBotinShell}
          setShowBot={setshowBotinShell}
          resetShellIrisContext={resetShellIrisContext}
          setIrisAppName={irisAppName === null ? "" : irisAppName}
          irisUtterance={iriS_Utterance === null ? "" : iriS_Utterance}
          tileName={tileName === null ? "" : tileName}
          currentUserData={currentUserData}
          irisBotIconClicked={irisBotIconClicked}
          setIrisBotIconClicked={setIrisBotIconClicked}
          irisToken={getIrisToken}
          SearchTerm={searchTerm}
          formDataToIris={formDataToIris}
          chatBotClicked={chatBotClicked}
          setChatBotClicked={setChatBotClicked}
          finalTileObject={finalTileObject}
          SubSegment={SubSegment}
          Segment={Segment}
        />
      );
    }
    return null;
  };

  //footer main
  const renderAppFooterMain = () => {
    const isDataArray = currentUserData && typeof currentUserData === "object";
    if (currentUserDbId !== 0 && isUserAuthorized && isDataArray) {
      return (
        <>
          <FooterMain
            selectedActiveTab={selectedActiveTab}
            isPrompt={isPrompt}
          />
        </>
      );
    }
    return null;
  };

  //handling error functions
  const handleNotFoundResponse = () => {
    setIsOpen(true);
    setCurentUserDbId(-1);
    setCurrentUserIsWelcomeMessage(false);
  };

  const handleErrorResponse = (error) => {
    if (error.response) {
      if (error.response.status === 204) {
        handleNotFoundResponse();
      } else if (error.response.status === 404) {
        handleNotFoundResponse();
      } else if (error.response.status === 401) {
        alert(
          "Oops! There seems to be an issue with the token. Please try refreshing the page or logging in again to resolve the issue."
        );
      }
    } else {
      console.error("An error occurred while fetching data.", error);
    }
  };

  //render main
  const handleHeaderButtonAccess = async () => {
    const logoId = "header-brand-logo-id";
    const logoElement = document.getElementById(logoId);

    if (appConfig.brandConfig.active && !logoElement) {
      const logoElements = document.querySelectorAll(
        `[aria-label^='${appConfig.appName}']`
      );
      if (logoElements.length > 0) {
        createLogoElement(logoElements[0]);
        logoElements.forEach((el) => {
          const wrapper = document.createElement("h1");
          // Create an <h1> element
          el.parentNode.insertBefore(wrapper, el);
          // Insert <h1> before el in the DOM
          wrapper.appendChild(el);
        });
      }
    }

    hideBrandImageIfNeeded();
    // Show/hide feedback button
    const showFeedback = shouldShowFeedback();
    toggleFeedbackButtonVisibility(showFeedback);

    updateAppLogoLinkStyle();
  };

  const createLogoElement = (parentElement) => {
    const image = document.createElement("img");
    image.id = "header-brand-logo-id";
    image.src = "/images/MCAPSHelp-SVG.svg";
    image.className = "header_logo";
    image.alt = intl.formatMessage(messages.headerLogoImageText);
    image.onclick = () => {
      window.location.href = window.location.origin;
    };
    parentElement.parentNode.insertBefore(image, parentElement);
  };

  const hideBrandImageIfNeeded = () => {
    if (
      isUserLoggedIn &&
      appConfig.brandConfig.active &&
      appConfig.brandConfig.loginScreenOnly
    ) {
      const brandImage: any = document.querySelector("#header-brand-logo-id");
      if (brandImage) brandImage.style.display = "none";
    }
  };

  const shouldShowFeedback = () => {
    return (
      currentAppState.isReady &&
      isUserLoggedIn &&
      appConfig.ocvFeedbackConfig.active
    );
  };

  const toggleFeedbackButtonVisibility = (showFeedback) => {
    const feedbackButton: any = document.querySelector(
      "#coher-header-ocv-button-id"
    );
    if (feedbackButton)
      feedbackButton.style.display = showFeedback ? "" : "none";
  };

  const updateAppLogoLinkStyle = () => {
    const appLogoLink: any = document.querySelector(
      `a[aria-label="${appConfig.appName}"]`
    );
    if (appLogoLink) {
      appLogoLink.style.color = "white !important";
    }
  };

  const renderMain = (): JSX.Element => {
    currentLocaleRef.current = currentLocale;
    isUserLoggedInRef.current = isUserLoggedIn;

    const newAppState = {
      ...currentAppState,
      user: currentUser,
      appConfig,
      locale: currentLocale,
      theme: currentTheme,
      isUserLoggedIn: isUserLoggedIn,
      isNavCollapsed: isNavCollapsed,
    };
    const contextValue: IApplicationContext = {
      extensionsRegistrationClient: extensionsRegistrationClient,
      appState: newAppState,
      userDashboardTiles: userDashboardTiles,
    };
    return (
      <ApplicationContext.Provider value={contextValue}>
        <>{renderApp()}</>
      </ApplicationContext.Provider>
    );
  };

  const getPrompts = async () => {
    try {
      if (context && context.telemetryClient) {
        setLoadingPrompt(true);
        const response = await getConsumptionAPI(
          `/api/UserWorkSpace/GetAiPrompt`,
          context.authClient
        );
        const lastselectedcategory = sessionStorage.getItem("FinalCategory");
        if (response.status === 200) {
          const promptOptions = response?.data.map((prompt) => ({
            key: prompt.key,
            text: prompt.key,
            value: prompt.value,
            category: prompt.category,
            isDefault: prompt.isDefault,
          }));
          setArr(promptOptions);
          const category = Array.from(
            new Set(response?.data.map((category) => category.category))
          ).map((category) => ({
            key: category,
            text: category,
            value: category,
          }));

          setCategory(category);
          const defaultCategory = promptOptions?.find(
            (item) => item.isDefault === 1
          );
          if (
            defaultCategory &&
            (lastselectedcategory === "" ||
              lastselectedcategory === null ||
              lastselectedcategory === undefined)
          ) {
            setSelectedCategory(defaultCategory.category); // Set default category
            // handleCategoryChange(null, { key: defaultCategory.category, text: defaultCategory.category }); // Load related keys
          } else {
            setSelectedCategory(lastselectedcategory);
          }
          setLoadingPrompt(false);
        }
      }
    } catch (error) {
      console.error("An error occurred while fetching data.", error);
    } finally {
      setLoadingPrompt(false);
    }
  };

  const PromptConfig = () => {
    useEffect(() => {
      setIsPrompt(true);
    }, []);
    return (
      <>
        {loadingPrompt && <ShimmerLoader count={5} />}
        {(!isAiUser || (!loadingPrompt && !isUserAuthorizedAi)) && (
          <Stack
            verticalAlign="center"
            styles={{ root: { alignItems: "center", marginTop: "20%" } }}>
            <Text
              styles={{
                root: {
                  fontSize: "x-large",
                  fontWeight: "bold",
                  textAlign: "center",
                },
              }}>
              {NOT_AI_Authorised}
            </Text>
            <Text
              styles={{
                root: {
                  fontSize: "x-large",
                  fontWeight: "bold",
                  textAlign: "center",
                },
              }}>
              {NOT_AI_AUTHORISED_TWO}
            </Text>
          </Stack>
        )}
        {!loadingPrompt && isUserAuthorizedAi && isAiUser && (
          <V2_Prompt
            Arr={Arr}
            loadingPrompt={loadingPrompt}
            getPrompts={getPrompts}
            setLoadingPrompt={setLoadingPrompt}
            Category={Category}
            selectedCategory={selectedCategory}
            Upn={currentUser.email}
            Area={Area}
          />
        )}
      </>
    );
  };

  const irisPayload = (data) => {
    setFormDataToIris((prev) =>
      prev !== data.setFormDataToIris ? data.setFormDataToIris : prev
    );
    setshowBotinShell((prev) =>
      prev !== data.setShowBot ? data.setShowBot : prev
    );
    setChatBotClicked((prev) =>
      prev !== data.setChatBotClicked ? data.setChatBotClicked : prev
    );
    setIris_Utterance((prev) =>
      prev !== data.setIrisUtterance ? data.setIrisUtterance : prev
    );
    set_irisAppName((prev) =>
      prev !== data.setIrisAppName ? data.setIrisAppName : prev
    );
    setTile_Name((prev) =>
      prev !== data.setTileName ? data.setTileName : prev
    );
    setMessageHistory((prev) =>
      prev !== data.messageData ? data.messageData : prev
    );
  };

  const CopilotConfig = () => {
    useEffect(() => {
      setIsCopilot(true);
    }, []);
    return (
      <>
        {
          isAiUser == true && isAiServicesEnabled == true ? (
            <FluentProvider theme={teamsLightTheme}>
              <Chat
                setFormDataToIris={setFormDataToIris}
                setShowBot={setshowBotinShell}
                setChatBotClicked={setChatBotClicked}
                setIrisUtterance={setIris_Utterance}
                setIrisAppName={set_irisAppName}
                setTileName={setTile_Name}
                setMessageData={messageHistory}
                setIrisPayload={irisPayload}
              />
            </FluentProvider>
          ) : (
            <FluentProvider theme={teamsLightTheme}>
              <Chat
                setFormDataToIris={setFormDataToIris}
                setShowBot={setshowBotinShell}
                setChatBotClicked={setChatBotClicked}
                setIrisUtterance={setIris_Utterance}
                setIrisAppName={set_irisAppName}
                setTileName={setTile_Name}
                setMessageData={messageHistory}
                setIrisPayload={irisPayload}
              />
            </FluentProvider>
          )
          // "You dont have the permission to view this Page"
        }
      </>
    );
  };

  const renderApp = (): JSX.Element => {
    handleHeaderButtonAccess();

    if (!isSasCalled && context.authClient["account"] != null) {
      getStorageSasToken();
      setSasCalled(true);
    }

    if (!envVariableCalled && context.authClient["account"] != null) {
      getEnvVariables();
      setEnvVarCalled(true);
    }

    return (
      <EventLoggingProviderFC parentContext={context}>
        <BrowserRouter>
          <>
            {currentUserDbId !== 0 && renderAppHeader()}
            {currentUserDbId !== 0 && (
              <div>
                <Route exact path="/prompt" component={PromptConfig} />
                <Route
                  exact
                  path="/imageprocessing"
                  component={CopilotConfig}
                />
              </div>
            )}
            {isCheckedMessage === false &&
              isSasLoaded &&
              envVariableLoaded &&
              !isPrompt &&
              userEmailCalled &&
              filteredAnnouncementss &&
              renderMultiPageWelcomeExp()}
            {/* {openTheSegmentPopUp && !isPrompt && renderSegmentPopup()} */}

            <main id="main" className={classes.main} tabIndex={-1}>
              {!isPrompt && !isCopilot && renderRoutes()}
            </main>
            {!isPrompt && renderAppFooter()}
            {renderAppFooterMain()}
          </>
        </BrowserRouter>
        <div
          role="alert"
          id="announce"
          aria-live="polite"
          aria-relevant="additions text"></div>
      </EventLoggingProviderFC>
    );
  };

  return renderMain();
};

export const V2_Shell = withRouter(injectIntl(V2_ShellComponent));
