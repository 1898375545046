import { V2_FORM_NAMES_API_ENDPOINT, INCIDENT_TILE_UTTERANCE } from "../../utils/constants";
import { getConsumptionAPI } from "../../utils/httpUtils";


export const  callapi = async (context,tileName,irisutterance="",area="") => {
    let finalIrisUtterance=irisutterance === INCIDENT_TILE_UTTERANCE?"":irisutterance;
    try {
      if (context ? context.telemetryClient : "") {
        const responseValidate = await getConsumptionAPI(
          `/api/V2_Service/ValidateParameters?TileName=${tileName}&IRISUtterence=${finalIrisUtterance}&Area=${area}`,
          context.authClient
        );
        console.log(responseValidate);
        if(responseValidate?.status === 200){
            return responseValidate?.data;
        }else{
            return "API Error = " + responseValidate?.status;
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

export const checkVisibilityOfTile = async (userId,tileName,context) => {

    if(context.authClient["account"] != null)
    {
        try {
          if (context ? context.telemetryClient : "") {
            const responseCheckVisibility = await getConsumptionAPI(
              `/api/V2_Service/CheckVisibility?TileName=${tileName}&UserID=${userId}`,
              context.authClient
            );
            console.log("responseServiceWorkspace",responseCheckVisibility);
            if(responseCheckVisibility?.status === 200){
                return responseCheckVisibility?.data;
            }else{
                return "API Error = " + responseCheckVisibility?.status;
            }
          }
        } catch (e) {
          console.log(e);
        }
     }
  };

  export const checkVisibilityWithDetails = async (tileName,context) => {

    if(context.authClient["account"] != null)
    {
        try {
          if (context ? context.telemetryClient : "") {
            const responseVisibility = await getConsumptionAPI(
              `/api/Services/CheckVisibilityWithDetails?TileName=${tileName}`,
              context.authClient
            );     
            if(responseVisibility?.status === 200){
                return responseVisibility?.data;
            }else{
                return "API Error = " + responseVisibility?.status;
            }
          }
        } catch (e) {
          console.log(e);
        }
     }
  };

  export const getFormNames = async (context) => {
    try {
      const response = await getConsumptionAPI(
        V2_FORM_NAMES_API_ENDPOINT,
        context.authClient
      );
      const responseData = response?.data;
      return responseData;
    } catch (error) {
      console.error("Error fetching form data:", error);
      return null;
    }
  };