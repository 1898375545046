import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsInitialized } from '../../../core/store';
import { SetInitialzeApp, setSubIssueTypes, getSubIssueTypes, SetIsConversationId } from '../../../core/store';
import { ChatDocument } from './Chat';
import { ServiceContext } from "@msx/platform-services";
import { getExternalConsumptionAPI } from '../../utils/httpUtilsExternalAPI';

interface ChatDocumentWrapperProps {
  setFormDataToIris?: any;
  setShowBot?: any;
  setChatBotClicked?: any;
  setIrisUtterance: any;
  setIrisAppName: any;
  setTileName: any;
  setMessageData?: any;
  setIrisPayload?: any;
  closeCopilot?: any;
  userEmail?: any;
}

const ChatDocumentWrapper: React.FC<ChatDocumentWrapperProps> = (props) => {
  const context = React.useContext(ServiceContext);
  const initialized = useSelector(getIsInitialized);
  const [refreshkey, setRefreshKey] = React.useState(0);
  // const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  var subIssueTypes = null;

  const refreshCopilot = () => {
    setRefreshKey(prevkey => prevkey + 1);
    dispatch(SetIsConversationId(false));
  };

  const fetchSubIssueTypes = async () => {
    try {
      const apiUrl = 'api/WWIC/GetEmployeePlanDetails';
      const response = await getExternalConsumptionAPI(apiUrl, context.authClient);
      // subIssueTypes = response?.data?.search?.map((item) => item.subIssueType) ?? [];
      subIssueTypes = response?.data?.search ?? [];
      console.log("subIssueTypes", subIssueTypes);
    } catch (error) {
      console.error("Error fetching subissue types", error);
      subIssueTypes = [];
    }
    finally {
      dispatch(setSubIssueTypes(subIssueTypes));
    }
  };

  useEffect(() => {
    if (!initialized) {
      fetchSubIssueTypes();
      dispatch(SetInitialzeApp(true));
    }
  }, []);

  return (
    <div>
      <ChatDocument key={refreshkey}
        setFormDataToIris={props.setFormDataToIris}
        setShowBot={props.setShowBot}
        setChatBotClicked={props.setChatBotClicked}
        setIrisUtterance={props.setIrisUtterance}
        setIrisAppName={props.setIrisAppName}
        setTileName={props.setTileName}
        setMessageData={props.setMessageData}
        setIrisPayload={props.setIrisPayload}
        closeCopilot={props.closeCopilot}
        userEmail={props.userEmail}
        subIssueTypes= {useSelector(getSubIssueTypes)}
        refreshCopilot={refreshCopilot}
      />
    </div>
  );
};

export default ChatDocumentWrapper;