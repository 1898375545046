
import React, { useContext, useEffect, useState } from "react";
import { IAppExtensionPage, ServiceContext } from "@msx/platform-services";
import { Routes } from "./App.routes";
import { Shell } from "../core/components/shell/Shell";
import { BrowserRouter as Router } from "react-router-dom";
import ErrorBoundary from "../errorHandling/errorBoundry";
import { V2_Shell } from "../core/components/shell/V2Component_Shell/V2_Shell";
import { getConsumptionAPI, getConsumptionAPILocal } from "./utils/httpUtils";
import { BusyIndicator } from "../core/components";
import V2_StartUp from "./components/V2_StartUp";
import { parse } from "path";
 
export interface Props {}
 
export const App: React.FC<Props> = () => {
  const renderRoutes = (extensonPages: IAppExtensionPage[]) => {
    return <Routes extensonPages={extensonPages} />;
  };
 
  const context = useContext(ServiceContext);
  const { authClient } = useContext(ServiceContext);
  const [data, setData] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
 
  // Fetch profile settings once the user is authenticated
  const fetchProfileSetting = async () => {
    try {
      if (context) {
        
      
       const response = await getConsumptionAPI(`/api/V2_User/GetProfileSetting`, context.authClient);
            if (response.status === 200) {
              setData(response.data);
              sessionStorage.setItem("profilesetting",response.data);
              //setData(false);
              setApiCalled(true);
            } else {
              throw new Error("Failed to fetch profile settings.");
            }
       

    }
    } catch (error) {
      console.error("Error fetching profile settings:", error);
    }
  };
 
  // Trigger the profile fetch if the user is logged in
  useEffect(() => {
    if (isUserLoggedIn) {
      let profilesetting =  sessionStorage.getItem("profilesetting");
     
      if(profilesetting == null)
      {    
       fetchProfileSetting();
      }
      else{

        const profilestatus = profilesetting === "false" ? false : true;
        setData(profilestatus);  setApiCalled(true);
      }
    }
  }, [isUserLoggedIn, context]);
 
  const renderMain = () => {
    if (!authClient) {
      return <BusyIndicator message="Loading..." />;
    }
 
    // Show V2_StartUp for login and auth flow
    if (!isUserLoggedIn) {
      return <V2_StartUp setIsUserLoggedIn={setIsUserLoggedIn} />;
    }
 
    // Render shell once logged in and API data is fetched
    return (
      <div style={{ backgroundColor: "#F5F5F5" }}>
        <ErrorBoundary>
          <Router>
            {apiCalled ? (
              data ? (
                <Shell onRenderRoutes={renderRoutes} />
              ) : (
                <V2_Shell onRenderRoutes={renderRoutes} />
              )
            ) : (
              <BusyIndicator message="Please Wait ..." />
            )}
          </Router>
        </ErrorBoundary>
      </div>
    );
  };
 
  return renderMain();
};
 
